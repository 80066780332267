import { useCallback, useContext } from 'react';
import * as api from '@/api';
import { SearchLoadingDispatchContext, SearchResultsDispatchContext } from '@/containers/WorkspaceGlobalSearch/Context';
import type { WorkspaceSearchFull } from '@/types';

export default function useSearchFetchAll() {
  const setResults = useContext(SearchResultsDispatchContext);
  const setLoading = useContext(SearchLoadingDispatchContext);
  const files = useCallback(async ({ filters }: FetchData) => {
    const result = await api.workspaces.globalSearch.files({
      filters: {
        keywords: filters.keywords,
        filetypes: filters.filetypes,
        tags: filters.tags,
      },
      size: 10,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        all: {
          ...prev.all,
          files: result,
        },
      }));
    }
  }, [setResults]);

  const folders = useCallback(async ({ filters }: FetchData) => {
    const result = await api.workspaces.globalSearch.folders({
      filters: {
        keywords: filters.keywords,
        tags: filters.tags,
      },
      size: 10,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        all: {
          ...prev.all,
          folders: result,
        },
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ filters }: FetchData) => {
    setLoading(prev => ({ ...prev, all: { ...prev.all, loading: true } }));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 250)),
      files({ filters }),
      folders({ filters }),
    ])
      .then(() => {
        setLoading(prev => ({ ...prev, all: { loading: false } }));
      });
  }, [files, folders, setLoading]);

  return fetch;
}

export { useSearchFetchAll };

type FetchData = {
  filters: Partial<WorkspaceSearchFull.Query>;
};