import { memo } from 'react';
import styled from '@emotion/styled';
import { GetStartedCard } from './Card';
import { items } from './data';
import { StarsIcon } from './Icon.Stars';

type Props = {
  className?: string;
  onClick: () => void;
};

export const ChatGetStarted = memo(({ className, onClick }: Props) => {

  const title = `Unlock insights from your organization's knowledge`;

  return (
    <Root className={className}>
      <Content>

        <Title>{title}</Title>

        <Cards>
          {items.map((item, index) => (
            <StyledGetStartedCard
              key={index}
              item={item} />
          ))}
        </Cards>
      </Content>

      <Footer onClick={onClick}>
        <FooterButton onClick={onClick}>
          Get Started!
        </FooterButton>
      </Footer>
    </Root>
  );
});

const borderRadius = 5;

const Root = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: 700,
  margin: '0 auto',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.white.main,
}));

const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: '100%',
  padding: `25px 10px`,
  boxSizing: 'border-box',

  borderRight: `2px solid ${theme.palette.gray.light1}`,
  borderLeft: `2px solid ${theme.palette.gray.light1}`,
  borderTop: `2px solid ${theme.palette.gray.light1}`,

  borderTopRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
}));

const StyledGetStartedCard = styled(GetStartedCard)(({
  marginTop: 30,
}));

const Cards = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& > *:not(:last-child)': {
    marginRight: 10,
  },
}));

const Title = styled.div(({ theme }) => ({
  fontSize: 24,
  fontFamily: theme.fonts.bold,
  width: 350,
  margin: '0 auto',
  marginBottom: 20,

  textAlign: 'center',
}));

const Footer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  padding: `20px 20px 40px 20px`,
  boxSizing: 'border-box',

  border: `2px solid ${theme.palette.gray.light1}`,
  borderTop: 'none',
  borderBottomRightRadius: borderRadius,
  borderBottomLeftRadius: borderRadius,
}));

const FooterButton = styled.button(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.white.main,
  fontFamily: theme.fonts.semiBold,

  backgroundColor: theme.isTrinityThemed ? theme.palette.purple.light8 : theme.palette.sentiment.primary.main,

  borderRadius: 5,
  width: 360,
  padding: 15,
}));
