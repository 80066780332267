import type { AxiosError } from 'axios';
import axios, { type AxiosInstance } from 'axios';
import { getAuthProvider } from '@/auth/auth-provider';
import { config } from '@/config';
import {
  logResponseErrorInterceptor,
  logRequestInterceptor,
  sessionValidationInterceptor,
  authRequestInterceptor,
  sentryTraceRequestInterceptor,
} from './interceptors';


const baseURL = `${config().BRAND_INSIGHTS_API_URL}`;

let authenticatedClient: AxiosInstance = null;
export function getAuthenticatedClient() {
  if (authenticatedClient) return authenticatedClient;

  authenticatedClient = axios.create({
    baseURL,
    headers: {
      'x-env': config().APP_ENVIRONMENT,
      'x-platform': config().APP_PLATFORM,
    },
  });

  authenticatedClient.interceptors.request.use(sentryTraceRequestInterceptor);
  authenticatedClient.interceptors.request.use(sessionValidationInterceptor);
  authenticatedClient.interceptors.request.use(authRequestInterceptor);
  authenticatedClient.interceptors.request.use(logRequestInterceptor);

  authenticatedClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const status = error?.status || error?.response?.status;

      if (status == 401) {
        getAuthProvider().error(new Error('Session is unauthorized!'));
      }

      logResponseErrorInterceptor(error);

      return Promise.reject(error);
    },
  );

  return authenticatedClient;
}

let unauthenticatedClient: AxiosInstance = null;
export function getUnauthenticatedClient() {
  if (unauthenticatedClient) return unauthenticatedClient;

  unauthenticatedClient = axios.create({
    baseURL,
    headers: {
      'x-env': config().APP_ENVIRONMENT,
      'x-platform': config().APP_PLATFORM,
    },
  });

  unauthenticatedClient.interceptors.request.use(sentryTraceRequestInterceptor);
  unauthenticatedClient.interceptors.request.use(authRequestInterceptor);
  unauthenticatedClient.interceptors.request.use(logRequestInterceptor);

  unauthenticatedClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      logResponseErrorInterceptor(error);
      return Promise.reject(error);
    },
  );

  return unauthenticatedClient;
}