import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/api';
import { ChatSessionQueriesContext } from '@/components/Chat/context';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import type { Chat } from '@/types';

type Props = {
  chatInstanceId: number;
  queryIdentifier: string;
};

export const useUpdateQueryRatingMutation = ({ chatInstanceId, queryIdentifier }: Props) => {

  const sessionQueries = useContext(ChatSessionQueriesContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const dispatchRating = useCallback((rating: Chat.Rating) => {
    dispatch({
      type: 'query-rated',
      payload: {
        queryIdentifier,
        rating,
      },
    });
  }, [dispatch, queryIdentifier]);

  const mutation = useMutation(['query:patch:rating'], (rating: Chat.Rating) => {
    return $api.updateQueryRating({
      chatInstanceId,
      queryIdentifier,
      rating,
    });
  }, {
    onMutate: rating => {
      const query = sessionQueries[queryIdentifier];
      dispatchRating(rating);
      return { oldRating: query.rating };
    },
    onError: (_res, _variables, context) => {
      dispatchRating(context.oldRating);
    },
    onSuccess: res => {
      dispatchRating(res.query.rating);
    },
  });

  return mutation;
};