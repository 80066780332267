import type { KeyboardEventHandler } from 'react';
import { useCallback, useContext, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { KolSearchStatus } from '@/enums/kol';
import type { KolSearchForm } from '@/types/kol';
import { AnalysisQueryHint } from '../presentation';
import {
  DispatchKolSearchFormContext,
  KolSearchStatusContext,
  KolSearchSubmitContext,
  KolSearchFormContext,
} from './context';
import { BasicSystemUserMessage } from './Message.System.Basic';

export const KolSearchFormMessage = () => {
  const searchStatus = useContext(KolSearchStatusContext);
  const handleSubmit = useContext(KolSearchSubmitContext);
  const formState = useContext(KolSearchFormContext);
  const setFormState = useContext(DispatchKolSearchFormContext);
  const inputFocused = useRef(false);

  const copy = `Let's get started. First lets find the HCP you'd like to profile!`;
  const instructions = `Enter details to find the HCP you would like to profile.`;

  const submitDisabled = useMemo(() => {
    return !formState.name || searchStatus !== KolSearchStatus.FormEntry;
  }, [formState.name, searchStatus]);

  const inputDisabled = useMemo(() => {
    return searchStatus !== KolSearchStatus.FormEntry;
  }, [searchStatus]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {

    if (!inputFocused.current) return;

    if (submitDisabled) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  }, [submitDisabled, handleSubmit]);

  const handleInputBlur = useCallback(() => {
    inputFocused.current = false;
  }, []);

  const handleInputFocus = useCallback(() => {
    inputFocused.current = true;
  }, []);

  return (
    <MessageContainer>
      <BasicSystemUserMessage>
        <Body>
          {copy}
          <Divider />
          <Instructions>{instructions}</Instructions>

          <KolSearchInputForm
            formState={formState}
            inputDisabled={inputDisabled}
            onKeyDown={handleKeyDown}
            onInputFocus={handleInputFocus}
            onInputBlur={handleInputBlur}
            setFormState={setFormState} />
          <Divider />
          <AnalysisQueryHint
            disabled={submitDisabled}
            onClick={handleSubmit}
            value="Search for profiles." />
        </Body>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

type FormProps = {
  className?: string;
  inputDisabled: boolean;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  formState: Partial<KolSearchForm>;
  setFormState: (form: Partial<KolSearchForm>) => void;
};

export const KolSearchInputForm = ({
  className,
  formState,
  inputDisabled,
  onInputBlur,
  onInputFocus,
  onKeyDown,
  setFormState,
}: FormProps) => {

  return (
    <div className={className}>
      <FormItem>
        <Label>Name</Label>
        <Input
          disabled={inputDisabled}
          onKeyDown={onKeyDown}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          type="text"
          placeholder="Name"
          value={formState.name}
          onChange={e => setFormState({ ...formState, name: e.target.value })} />
      </FormItem>
    </div>
  );
};

const Body = styled.div({
  width: 500,
});

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: 2,
  backgroundColor: theme.palette.gray.light2,
  margin: '15px 0',
}));

const Instructions = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  marginBottom: 20,
}));

const Label = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  width: 100,
}));

const FormItem = styled.div({
  display: 'flex',
  alignItems: 'center',

  '&:not(:last-child)': {
    marginBottom: 15,
  },
});

const Input = styled.input(({ theme }) => ({
  fontSize: 16,
  padding: 8,
  // width: 250,
  width: '100%',
  borderRadius: 5,
  border: `2px solid ${theme.palette.gray.light1} `,
  fontFamily: theme.fonts.regular,

  '&:focus': {
    borderColor: theme.isTrinityThemed ? theme.palette.blue.main : theme.palette.primary.main,
  },

  '&::placeholder': {
    // color: theme.palette.gray.light1,
    fontFamily: theme.fonts.regular,
  },
}));