import { getAuthenticatedClient } from './client';
import type * as Workspaces from './interfaces/workspaces';
import { safeIsoDate, safeJsonDate } from './transformers/date';
import { download } from './utils';

export * as globalSearch from './workspaces.global-search';

export const fetchWorkspaceFiles = (data: Workspaces.FetchWorkspaceFiles.Request) => {
  return getAuthenticatedClient().get<Workspaces.FetchWorkspaceFiles.Response<string>>(`/workspaces/${data.workspaceId}/objects/${data.objectId}`)
    .then(res => ({
      breadcrumbs: res.data.breadcrumbs.map(x => ({
        ...x,
        createdOn: safeJsonDate(x.createdOn),
        expiresOn: safeIsoDate(x.expiresOn),
      })),
      items: res.data.items.map(x => ({
        bookmarked: x.bookmarked,
        object: {
          ...x.object,
          createdOn: safeJsonDate(x.object.createdOn),
          expiresOn: safeIsoDate(x.object.expiresOn),
        },
        file: x.file ? {
          ...x.file,
          createdOn: safeJsonDate(x.file.createdOn),
          publishedOn: safeIsoDate(x.file.publishedOn),
        } : null,
        folder: x.folder ? {
          ...x.folder,
          createdOn: safeJsonDate(x.folder.createdOn),
        } : null,
        meta: x.meta,
        tags: x.tags,
      })),
    }));
};

export const fetchWorkspaceFile = (data: { fileId: number }) => {
  return getAuthenticatedClient().get<Workspaces.FetchWorkspaceFile.Response<string>>(`/workspaces/files/${data.fileId}`)
    .then(res => ({
      breadcrumbs: res.data.breadcrumbs.map(x => ({
        ...x,
        createdOn: safeJsonDate(x.createdOn),
        expiresOn: safeIsoDate(x.expiresOn),
      })),
      object: {
        ...res.data.object,
        createdOn: safeJsonDate(res.data.object.createdOn),
        expiresOn: safeIsoDate(res.data.object.expiresOn),
      },
      file: {
        ...res.data.file,
        createdOn: safeJsonDate(res.data.file.createdOn),
        lastDownloadedOn: safeJsonDate(res.data.file.lastDownloadedOn),
        lastModifiedOn: safeJsonDate(res.data.file.lastModifiedOn),
        publishedOn: safeIsoDate(res.data.file.publishedOn),
      },
      tags: res.data.tags,
      preview: res.data.preview,
    }));
};

export const fetchWorkspaceFolder = (data: { folderId: number }) => {
  return getAuthenticatedClient().get<Workspaces.FetchWorkspaceFolder.Response<string>>(`/workspaces/folders/${data.folderId}`)
    .then(res => ({
      breadcrumbs: res.data.breadcrumbs.map(x => ({
        ...x,
        createdOn: safeJsonDate(x.createdOn),
        expiresOn: safeIsoDate(x.expiresOn),
      })),
      object: {
        ...res.data.object,
        createdOn: safeJsonDate(res.data.object.createdOn),
        expiresOn: safeIsoDate(res.data.object.expiresOn),
      },
      folder: {
        ...res.data.folder,
        createdOn: safeJsonDate(res.data.folder.createdOn),
      },
    }));
};

export const getFileSummary = (data: Workspaces.GetFileSummary.Request): Promise<Workspaces.GetFileSummary.Response> => {
  return getAuthenticatedClient().get<Workspaces.GetFileSummary.Response>(`/workspaces/files/${data.fileId}/summary`).then(res => res.data);
};

export const regenerateFileSummary = (data: Workspaces.RegenerateFileSummary.Request): Promise<Workspaces.RegenerateFileSummary.Response> => {
  return getAuthenticatedClient().post(`/workspaces/files/${data.fileId}/regenerate-summary`, {}).then(res => res.data);
};

export const downloadFileSummary = (data: Workspaces.RegenerateFileSummary.Request) => {
  return download(getAuthenticatedClient(), `/workspaces/files/${data.fileId}/summary/download`);
};