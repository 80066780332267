import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { BrandInsightsCapabilities } from '@/types';
import type { ILink } from './interfaces';

type SideBarExpandedContextValue = [boolean, Dispatch<SetStateAction<boolean>>];

export const SideBarExpandedContext = createContext<SideBarExpandedContextValue>(null);
export const BrandInsightsModalContext = createContext<Modal.ToggleContext>([false, () => { }]);
export const LinkContext = createContext<ILink.LinkContextValue>(undefined);
export const LinkDispatchContext = createContext<Dispatch<ILink.Action>>(undefined);
export const ChatViewTypeContext = createContext<ChatViewType>(undefined);
export const CapabilitiesContext = createContext<BrandInsightsCapabilities>({
  canApplyFilters: false,
  canViewTraces: false,
  canViewExternalLinks: false,
});

export declare namespace Modal {
  export type ToggleContext = [isOpen: boolean, toggleModal: () => void];
}

export enum ChatViewType {
  Modal = 'modal',
  Page = 'page',
}