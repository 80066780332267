import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSearchFiltersQuery } from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import type { WorkspaceSearchFull } from '@/types';
import { getLocationFor } from '@/utils';
import styles from './style/ViewAll.module.css';

type Props = {
  tab: WorkspaceSearchFull.SearchTab;
};

export default function ViewAll({ tab }: Props) {
  const [query] = useSearchFiltersQuery();

  const to =
    useMemo(() => getLocationFor.workspaces.search({ keywords: query.keywords, tab }), [query.keywords, tab]);

  return (
    <Link className={styles.root} to={to}>
      {`View All >`}
    </Link>
  );
}

export { ViewAll };