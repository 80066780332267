import { useCallback, useState } from 'react';
import type { WorkspaceGlobalSearchBarFilters, WorkspaceGlobalSearchBarFiltersAggregations } from '@/types';
import { WorkspaceGlobalSearchBarFiltersContext } from './Context';

export default function Filters({ children }: ChildrenProps) {
  const [activeFilters, setActiveFilters] = useState<WorkspaceGlobalSearchBarFilters>(mergeInitialActive());
  const [aggregationsFilters, setAggregationsFilters] = useState<WorkspaceGlobalSearchBarFiltersAggregations>(mergeInitialAggs());

  const clearActiveFilters = useCallback(() => {
    setActiveFilters(mergeInitialActive());
  }, []);

  const clearAggregationsFilters = useCallback(() => {
    setAggregationsFilters(mergeInitialAggs());
  }, []);

  const value = {
    activeFilters,
    aggregationsFilters,
    setActiveFilters,
    setAggregationsFilters,
    clearActiveFilters,
    clearAggregationsFilters,
  };

  return (
    <WorkspaceGlobalSearchBarFiltersContext.Provider value={value}>
      {children}
    </WorkspaceGlobalSearchBarFiltersContext.Provider>
  );
}

function mergeInitialActive(): WorkspaceGlobalSearchBarFilters {
  return {};
}

function mergeInitialAggs(): WorkspaceGlobalSearchBarFiltersAggregations {
  return {
    // brand: -1,
    // project: -1,
    folder: -1,
    file: -1,
    tag: -1,
  };
}

export { Filters };