import { createNamedContext } from '@/utils';
import type {
  WorkspaceGlobalSearchBarResultsContextValue,
  WorkspaceGlobalSearchBarDispatchContextValue,
  WorkspaceGlobalSearchBarFetchContextValue,
  WorkspaceGlobalSearchBarPopupContextValue,
  WorkspaceGlobalSearchBarQueryContextValue,
  WorkspaceGlobalSearchBarFiltersContextValue,
  WorkspaceGlobalSearchKeyboardContextValue,
  WorkspaceGlobalSearchBarResetContextValue,
} from './interfaces';

export const WorkspaceGlobalSearchBarResultsContext =
  createNamedContext<WorkspaceGlobalSearchBarResultsContextValue>(undefined, 'WorkspaceGlobalSearchBarResults.Context');

export const WorkspaceGlobalSearchBarDispatchContext =
  createNamedContext<WorkspaceGlobalSearchBarDispatchContextValue>(undefined, 'WorkspaceGlobalSearchBarDispatch.Context');

export const WorkspaceGlobalSearchBarFetchContext =
  createNamedContext<WorkspaceGlobalSearchBarFetchContextValue>(undefined, 'WorkspaceGlobalSearchBarFetch.Context');

export const WorkspaceGlobalSearchBarPopupContext =
  createNamedContext<WorkspaceGlobalSearchBarPopupContextValue>(undefined, 'WorkspaceGlobalSearchBarPopup.Context');

export const WorkspaceGlobalSearchBarQueryContext =
  createNamedContext<WorkspaceGlobalSearchBarQueryContextValue>(undefined, 'WorkspaceGlobalSearchBarQuery.Context');

export const WorkspaceGlobalSearchBarFiltersContext =
  createNamedContext<WorkspaceGlobalSearchBarFiltersContextValue>(undefined, 'WorkspaceGlobalSearchBarFilters.Context');

export const WorkspaceGlobalSearchKeyboardContext =
  createNamedContext<WorkspaceGlobalSearchKeyboardContextValue>(undefined, 'WorkspaceGlobalSearchKeyboard.Context');

export const WorkspaceGlobalSearchBarResetContext =
  createNamedContext<WorkspaceGlobalSearchBarResetContextValue>(undefined, 'WorkspaceGlobalSearchBarReset.Context');
