import EventEmitter from 'events';
import * as Sentry from '@sentry/react';
import { z } from 'zod';
import { isDevEnv } from '@/config/utils';
import { MyInsightsMock, MyInsightsOnline, type MyInsights } from '@/lib/myinsights';
import { getLocalStorage, getSessionStorage } from '@/lib/storage';
import type { AuthSource, AuthProviderEvent, AuthenticateJWT } from './auth-provider.base';
import { BaseAuthProvider } from './auth-provider.base';

export class VeevaAuthProvider extends BaseAuthProvider {
  static #tokenKey = 'msl-copilot:veeva:token';
  static #authAttemptsKey = 'msl-copilot:veeva:auth-attempts';

  #eventEmitter: EventEmitter;
  #myInsights: MyInsights;

  #refreshInterval: NodeJS.Timeout;
  #isRefreshingToken: boolean;
  #isAuthenticated: boolean;
  #expiry: Date;

  constructor() {
    super();
    this.#isAuthenticated = false;
    this.#eventEmitter = new EventEmitter();
    this.#myInsights = isDevEnv() ? new MyInsightsMock() : new MyInsightsOnline();
  }

  get isAuthenticated(): boolean {
    return this.#isAuthenticated;
  }

  get expiry(): Date {
    return this.#expiry;
  }

  async authenticate(data: AuthenticateJWT) {
    return Sentry.startSpan({ name: 'Veeva Authenticate', op: 'veeva-auth.authenticate' }, async () => {
      try {
        const previousIsAuthenticated = this.isAuthenticated;

        const token = await this.#myInsights.getSSOToken();
        const parsedToken = this.#decodeToken(token);
        this.#storeToken({ token });
        this.#expiry = parsedToken.exp;
        this.#isAuthenticated = true;

        if (this.#refreshInterval) clearInterval(this.#refreshInterval);
        this.#refreshInterval = setInterval(() => {
          this.refreshToken();
        }, 30 * 60 * 1000);

        if (!previousIsAuthenticated) {
          this.#emit({ name: 'auth/login' });
        }
      } catch (e) {
        this.error(e);
        this.logout();
      }
    });
  }

  async error(error: Error) {
    Sentry.captureException(error);
    this.#emit({ name: 'auth/error', error });
  }

  #decodeToken(token: string) {
    return Sentry.startSpan({ name: 'Veeva Decode Token', op: 'veeva-auth.decodeToken' }, () => {
      const [_, body] = token.split('.');

      const parsed = ParsedJWT.safeParse(JSON.parse(atob(body)));
      if (!parsed.success) throw new Error(`Could not parse decoded token: ${JSON.stringify(parsed.error, null, 2)}`);
      return parsed.data;
    });
  }

  get authSource(): AuthSource {
    return 'jwt';
  }

  async refreshToken() {
    return Sentry.startSpan({ name: 'Veeva Refresh Token', op: 'veeva-auth.refreshToken' }, async () => {
      if (!this.#isRefreshingToken) return;
      try {
        this.#isRefreshingToken = true;
        const { token: expiredToken } = this.#getToken();
        if (!expiredToken) return;

        const token = await this.#myInsights.refreshSSOToken(expiredToken);

        const parsedToken = this.#decodeToken(token);
        this.#storeToken({ token });
        this.#expiry = parsedToken.exp;
        this.#isAuthenticated = true;
        this.#isRefreshingToken = false;
      } catch (e) {
        this.#isRefreshingToken = false;
        this.error(e);
        this.logout();
      }
    });
  }

  get isRefreshingToken() {
    return this.isRefreshingToken;
  }

  get accessToken() {
    return Sentry.startSpan({ name: 'Veeva Get Access Token', op: 'veeva-auth.accessToken' }, () => {
      const data = this.#getToken();
      if (!data) return null;
      return data.token;
    });
  }

  get authAttempts() {
    const $session = getSessionStorage();
    return +($session.getItem(VeevaAuthProvider.#authAttemptsKey) || '0');
  }

  incrementAuthAttempts() {
    const $session = getSessionStorage();
    $session.setItem(VeevaAuthProvider.#authAttemptsKey, `${this.authAttempts + 1}`);
  }

  logout() {
    return Sentry.startSpan({ name: 'Veeva Logout', op: 'veeva-auth.logout' }, () => {
      const previousIsAuthenticated = this.isAuthenticated;

      this.#isAuthenticated = false;
      this.#expiry = null;
      this.#destroy();

      if (previousIsAuthenticated) {
        this.#emit({ name: 'auth/logout' });
      }
    });
  }

  #emit(e: AuthProviderEvent) {
    return this.#eventEmitter.emit('auth', e);
  }

  addListener(listener: (e: AuthProviderEvent) => void) {
    this.#eventEmitter.addListener('auth', listener);

  }
  removeListener(listener: (e: AuthProviderEvent) => void) {
    this.#eventEmitter.removeListener('auth', listener);
  }

  #storeToken(token: TokenData) {
    return Sentry.startSpan({ name: 'Veeva Store Token', op: 'veeva-auth.storeToken' }, () => {
      const $storage = getLocalStorage();
      $storage.setItem(VeevaAuthProvider.#tokenKey, JSON.stringify(TokenData.parse(token)));
    });
  }

  #getToken() {
    return Sentry.startSpan({ name: 'Veeva Get Token', op: 'veeva-auth.getToken' }, () => {
      const $storage = getLocalStorage();
      try {
        return TokenData.parse(JSON.parse($storage.getItem(VeevaAuthProvider.#tokenKey)));
      } catch {
        $storage.removeItem(VeevaAuthProvider.#tokenKey);
        return null;
      }
    });
  }

  #destroy() {
    return Sentry.startSpan({ name: 'Veeva Destroy', op: 'veeva-auth.destroy' }, () => {
      try {
        const $storage = getLocalStorage();
        $storage.removeItem(VeevaAuthProvider.#tokenKey);
        // $storage.removeItem(VeevaAuthProvider.#authAttemptsKey);
      } catch (error) {
        Sentry.captureException(error);
      }
    });
  }
}

const TokenData = z.object({
  token: z.string(),
});
type TokenData = z.infer<typeof TokenData>;

const ParsedJWT = z.object({
  email: z.string(),
  exp: z.coerce.number().transform(u => new Date(u * 1000)),
});