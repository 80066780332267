import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OpenCrmNoteContext, SetOpenCitationContext } from '@/components/Chat/context';
import type { ChatCitationType } from '@/enums';
import type { InteractionType } from '@/enums/interactions';
import { interactionTypesMap } from '@/enums/interactions';
import type { Chat } from '@/types';
import { MessageCitation, Header, Title } from './Citation';
import { StyledActionButton } from './Citation.Toggle';

type Props = {
  interactionType: InteractionType;
  items: Chat.Citation<ChatCitationType.CrmNote>[];
};

export const InteractionCitations = ({ interactionType, items }: Props) => {

  const [isOpen, setIsOpen] = useState(false);

  const openCitation = useContext(OpenCrmNoteContext);
  const setOpenCitation = useContext(SetOpenCitationContext);

  const isChildCitationOpen = useMemo(() => {
    return items.some(s => s.id === openCitation);
  }, [items, openCitation]);

  useEffect(() => {
    if (isChildCitationOpen) {
      setIsOpen(true);
    }
  }, [isChildCitationOpen]);

  const toggleManuallyOpen = useCallback(() => {
    setIsOpen(s => !s);
    if (isOpen && isChildCitationOpen) {
      setOpenCitation(null);
    }
  }, [isChildCitationOpen, isOpen, setOpenCitation]);

  return (
    <div>
      <Header onClick={toggleManuallyOpen}>
        <Title>{interactionTypesMap[interactionType]}s ({items.length})</Title>
        <StyledActionButton>
          [{isOpen ? 'Hide' : 'Show'} All]
        </StyledActionButton>
      </Header>
      {isOpen && (
        <>
          {items.map((item, i) => (
            <MessageCitation key={i} item={item} />
          ))}
        </>
      )}
    </div>
  );
};