import { useCallback, useEffect, useContext, memo } from 'react';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Bookmark } from 'react-feather';
import type { Cell } from 'react-table';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { WorkspaceObjectType } from '@/enums';
/* import * as $api from '@api/workspaces'; */
/* import { Checkbox } from '@/components/Checkbox'; */
import { SelectedObjectsContext } from './context';
import { NameCell } from './Files.Table.Cells.Name';
/* import { ActionsCell } from './Files.Table.Cells.Actions'; */
import { TagsCell } from './Files.Table.Cells.Tags';
import type { IFilesTable } from './interfaces';
import styles from './style/Files.Table.Cells.module.css';

const OwnerCell = memo(({ row: { original: data } }: Cell<IFilesTable.Item>) => {
  return (
    <>{data.meta.owner.displayName}</>
  );
});

const CreatedOnCell = memo((props: Cell<IFilesTable.Item>) => {
  return (
    <>{format(props.row.original.object.createdOn, 'MM/dd/yyyy')}</>
  );
});

function BookmarkCell({ row: { original: data } }: Cell<IFilesTable.Item>) {
  /*
  const [isBookmarked, setIsBookmarked] = useState(data.bookmarked);

  useEffect(() => {
    setIsBookmarked(data.bookmarked);
  }, [data.bookmarked]);

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ['file.bookmarkObject'],
    mutationFn: async () => {
      return $api.file.bookmarkObject({
        objectId: data.object.id,
        bookmark: !isBookmarked,
        workspaceId: data.object.workspaceId,
      });
    }, onSuccess: res => {
      data.bookmarked = res.bookmarked;
    },
  });

  const toggleBookmark = useCallback(() => {
    mutateAsync();
  }, [mutateAsync]);
 */
  const { palette, theme: wl } = useContext(AppThemingContext);
  const fill = wl?.enabled ? palette.primary.dark : `#2596be`;
  const color = wl?.enabled ? palette.primary.light : `#737373`;

  const disabled = true;

  return (
    <button
      className={disabled ? styles.disabled : ''}
      disabled={disabled}
      onClick={() => {}}>
      <Bookmark
        fill={disabled ? `var(--pri-01)` : data.bookmarked ? fill : 'var(--pri-01'}
        color={disabled ? `var(--gray-l)` : data.bookmarked ? fill : color}
        size={24} />
    </button>
  );

}
/*
function CheckboxCell({ row: { original: data } }: Cell<IFilesTable.Item>) {
  const { selectedObjectIds, toggleSelectedObject } = useContext(SelectedObjectsContext);

  return (
    <Checkbox
      className={styles.checkbox}
      checked={selectedObjectIds.includes(data.object.id)}
      onChange={() => toggleSelectedObject(data.object.id)} />
  );
}
*/
export default {
  Actions: () => null, // ActionsCell,
  Checkbox: () => null, // CheckboxCell,
  CreatedOn: CreatedOnCell,
  Name: NameCell,
  Owner: OwnerCell,
  Bookmark: BookmarkCell, // BookmarkCell,
  Tags: TagsCell, // TagsCell,
};