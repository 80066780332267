import { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as api from '@/api';
import { useRegisterChatPageContext } from '@/components/Chat';
import { TabViewSkeleton } from '@/components/TabView';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { ChatContextType } from '@/enums/chat';
import styles from '@/screens/Workspace.FilesTable/style/Files.Table.module.css';
import type { BrandInsightsPageContext } from '@/types';
import { FolderLoadingContext, FolderStateContext } from './Context';
import { FolderTabView } from './Folder.TabView';
import { Header } from './Header';
import { useFolderTabsMap } from './useFolderTabsMap';

type Props = unknown;

const FolderTabs = (props: Props) => {

  const isLoading = useContext(FolderLoadingContext);
  const tabsMap = useFolderTabsMap();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header />
        {isLoading
          ? <TabViewSkeleton />
          : <FolderTabView map={tabsMap} />}
      </div>
    </div>
  );
};

type Params = {
  folderId: string;
};

export const WorkspaceFolder = (props: unknown) => {
  const params = useParams<Params>();

  const query = useQuery({
    queryKey: [
      `get:workspaces/folders`,
      +params.folderId,
    ],
    queryFn: () => {
      return api.workspaces.fetchWorkspaceFolder({
        folderId: +params.folderId,
      });
    },
  });

  const folderContext: BrandInsightsPageContext.Folder = useMemo(() => {
    return {
      type: ChatContextType.Folder,
      data: {
        objectId: query.data?.folder?.objectId,
        folderId: query.data?.folder?.id,
      },
      metadata: {
        supportsUploads: !!query.data?.folder?.metadata?.linkedChatInstanceIdentifier,
        name: query.data?.folder?.name,
      },
    };
  }, [query.data?.folder]);

  const register = useRegisterChatPageContext();

  useEffect(() => {
    if (!folderContext) return;

    if (folderContext.data.objectId && folderContext.metadata.name) {
      register(folderContext);
    }
  }, [register, folderContext]);

  return (
    <FolderLoadingContext.Provider value={query.isInitialLoading}>
      <FolderStateContext.Provider value={query.data}>
        <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs || []}>
          <FolderTabs />
        </WorkspaceBreadcrumbsContext.Provider>
      </FolderStateContext.Provider>
    </FolderLoadingContext.Provider>
  );
};