import { useMemo } from 'react';
import { Permission } from '@/enums/permission';
import { useAppSelector } from '@/store';

export const useBrandInsightsCapabilities = () => {

  const author = useAppSelector(state => state.user);

  const capabilities = useMemo(() => {
    if (!author?.permissions) {
      return {
        canApplyFilters: false,
        canViewTraces: false,
        canViewExternalLinks: false,
      };
    }

    return {
      canApplyFilters: author.permissions.includes(Permission.ApplyFilters),
      canViewTraces: author.permissions.includes(Permission.ViewChatTraces),
      canViewExternalLinks: true,
    };
  }, [author?.permissions]);

  return capabilities;
};