import { useContext } from 'react';
import { format } from 'date-fns';
import * as Tab from '@/components/presentation/Workspace.Tab.Pane';
import { FolderStateContext } from './Context';

export const FolderTabDetails = () => {
  const { object } = useContext(FolderStateContext);

  return (
    <Tab.Panes.Container>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Items.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>
              Name
            </Tab.Item.TextLabel>
            <Tab.Item.Value>
              {object.name}
            </Tab.Item.Value>
          </Tab.Item.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>
              Owner
            </Tab.Item.TextLabel>
            <Tab.Item.Value>
              {object.owner.name}
            </Tab.Item.Value>
          </Tab.Item.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>
              Created On
            </Tab.Item.TextLabel>
            <Tab.Item.Value>
              {format(object.createdOn, 'M/d/yyyy')}
            </Tab.Item.Value>
          </Tab.Item.Container>
        </Tab.Items.Container>
      </Tab.Pane.Pane>
    </Tab.Panes.Container>
  );
};