import type { Cell } from 'react-table';
import { cx } from '@/utils';
import type { RowData } from './interfaces';
import styles from './style/Worksheet.module.css';

export function DataCell({ column, row: { original: data } }: Cell<RowData>) {
  const cell = data.cells[column.id];

  function renderContent() {
    const className = cx(styles.cell, {
      [styles.alignRight]: cell?.t === 'n',
    });

    return (
      <div className={className}>
        {cell?.f?.toString() || cell?.r?.toString()}
      </div>
    );
  }

  if (cell?.l) {
    return (
      <a
        className={styles.link}
        href={cell.l}
        target="_blank"
        rel="noopener noreferrer">
        {renderContent()}
      </a>
    );
  }

  return renderContent();
}