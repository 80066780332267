import { useContext, useMemo } from 'react';
import { ChatSessionKolSearchesContext, KolSearchIdentifierContext } from '../context';

export const useIsLastKolSearch = () => {

  const searches = useContext(ChatSessionKolSearchesContext);
  const searchIdentifier = useContext(KolSearchIdentifierContext);

  return useMemo(() => {
    return searches.length > 0 && searches[searches.length - 1].identifier === searchIdentifier;
  }, [searches, searchIdentifier]);
};