import type { MouseEventHandler } from 'react';
import { useCallback, useContext, useState, useRef } from 'react';
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GroupIcon from '@mui/icons-material/Group';
import LinkIcon from '@mui/icons-material/Link';
import ReplyIcon from '@mui/icons-material/Reply';
import { Bookmark } from 'react-feather';
import { useIsFullPageChat } from '@/components/Chat/hooks';
import { Tooltip } from '@/components/presentation';
import { ReportFormat } from '@/enums/reports';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { buildBrandInsightsChatLink, shouldForwardProp } from '@/utils';
import { ChatHistoryItemContext } from './context';
import { useToggleChatBookmark } from './hooks';
import { useDownloadChatInstance } from './hooks/useDownloadChatInstance';
import { useDeleteChatModal } from './Modal.DeleteChat';
import { useRenameChatModal } from './Modal.RenameChat';
import { useShareChatModal } from './Modal.ShareChat';

export const RenameChat = () => {
  const { item } = useContext(ChatHistoryItemContext);

  const [toggleRenameModal, RenameModal] = useRenameChatModal();

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    toggleRenameModal();
  }, [toggleRenameModal]);

  return (
    <div>
      <Tooltip disableInteractive title="Rename Chat">
        <StyledRenameIcon onClick={handleClick} />
      </Tooltip>
      <RenameModal chatInstance={item} />
      <Text>  Rename Chat</Text>
    </div>
  );
};

export const DeleteChat = () => {
  const { item } = useContext(ChatHistoryItemContext);

  const [toggleDeleteModal, DeleteModal] = useDeleteChatModal();

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    toggleDeleteModal();
  }, [toggleDeleteModal]);

  return (
    <div onClick={handleClick}>
      <StyledDeleteIcon />
      <DeleteModal chatInstance={item} />
      <Text>  Delete Chat</Text>
    </div>
  );
};

export const DownloadChat = () => {
  const { item } = useContext(ChatHistoryItemContext);

  const { download } = useDownloadChatInstance({
    chatInstanceId: item.id,
    name: item.name,
  });

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    download({
      title: `Generating Export`,
      name: item.name,
      extension: ReportFormat.Word,
    });
  }, [download, item.name]);

  return (
    <div onClick={handleClick}>
      <StyledDownloadIcon />
      <Text> Download Chat</Text>
    </div>
  );
};

export const CopyChatLink = () => {
  const { item } = useContext(ChatHistoryItemContext);
  const isStandaloneChat = useIsFullPageChat();
  const [copied, setCopied] = useIconFlipper(2500);

  const copy = useCopyToClipboard();

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    copy(buildBrandInsightsChatLink({
      chatIdentifier: item.identifier,
      isStandaloneChat,
    }));
    setCopied();
  }, [isStandaloneChat, item.identifier, copy, setCopied]);

  return (
    <div onClick={handleClick}>
      {copied
        ? <StyledDoneIcon />
        : <StyledLinkIcon onClick={handleClick} />}
      <Text> Copy Link</Text>

    </div>
  );
};

export const BookmarkChat = () => {
  const { item } = useContext(ChatHistoryItemContext);
  const bookmarkMutation = useToggleChatBookmark({ chatInstanceId: item.id });

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    bookmarkMutation.mutate(!item.bookmarked);
  }, [bookmarkMutation, item.bookmarked]);

  if (!item.bookmarked) return null;

  return (
    <BookmarkButton onClick={onClick}>
      <StyledBookmarkIcon size={18} bookmarked={item.bookmarked} />
    </BookmarkButton>
  );
};

type SharedChatProps = {
  className?: string;
};

export const SharedChatInfo = ({ className }: SharedChatProps) => {
  const { item } = useContext(ChatHistoryItemContext);

  return (
    <Tooltip title={`Shared by ${item.shared.sharedBy.profile.firstName} ${item.shared.sharedBy.profile.lastName}`}>
      <StyledSharedInfoIcon className={className} />
    </Tooltip>
  );
};

export const DeleteSharedChat = () => {
  const { item } = useContext(ChatHistoryItemContext);

  const [toggleDeleteModal, DeleteModal] = useDeleteChatModal();

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    toggleDeleteModal();
  }, [toggleDeleteModal]);

  return (
    <div onClick={handleClick}>
      <StyledDeleteIcon />
      <DeleteModal chatInstance={item} />

      <Text>  Delete Chat</Text>

    </div>
  );
};

export const ShareChat = () => {
  const { item } = useContext(ChatHistoryItemContext);

  const [toggleShareModal, ShareModal] = useShareChatModal();

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    toggleShareModal();
  }, [toggleShareModal]);

  return (
    <div onClick={handleClick}>
      <StyledShareIcon />
      <ShareModal chatInstance={item} />
      <Text>  Share Chat</Text>
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export function useIconFlipper(timeoutMs: number) {
  const [flipped, setFlipped] = useState(false);
  const timer = useRef<NodeJS.Timeout>();

  const handleSetFlipped = useCallback(() => {
    setFlipped(true);

    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setFlipped(false);
    }, timeoutMs);
  }, [timeoutMs]);

  return [flipped, handleSetFlipped] as const;
}

const StyledRenameIcon = styled(DriveFileRenameOutlineIcon)`
  color: ${props => props.theme.palette.black.light1};
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  transition: 0.15s;

  &:hover {
    opacity: 1;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${props => props.theme.palette.black.light1};
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  transition: 0.15s;

  &:hover {
    opacity: 1;
  }
`;

const StyledDownloadIcon = styled(FileDownloadIcon)`
  color: ${props => props.theme.palette.black.light1};
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  transition: 0.15s;

  &:hover {
    opacity: 1;
  }
`;

const StyledLinkIcon = styled(LinkIcon)`
  color: ${props => props.theme.palette.black.light1};
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  transition: 0.15s;

  &:hover {
    opacity: 1;
  }
`;

const StyledSharedInfoIcon = styled(GroupIcon)`
  color: ${props => props.theme.palette.black.light1};
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  transition: 0.15s;

  &:hover {
    opacity: 1;
  }
`;

const StyledShareIcon = styled(ReplyIcon)`
  color: ${props => props.theme.palette.black.light1};
  cursor: pointer;
  font-size: 20px;
  opacity: 0.8;
  transition: 0.15s;
  transform: scaleX(-1);

  &:hover {
    opacity: 1;
  }
`;

const StyledDoneIcon = styled(DoneIcon)`
  color: ${props => props.theme.palette.green.main};
  font-size: 20px;
  transition: 0.15s;
`;

const Text = styled('div')`
  display: block;
  margin-left: 25px;
  margin-top: -25px;
`;

type StyledBookmarkButtonProps = {
  bookmarked: boolean;
};
const StyledBookmarkIcon = styled(Bookmark, { shouldForwardProp: shouldForwardProp('bookmarked') })<StyledBookmarkButtonProps>(({ theme, bookmarked }) => ({
  fill: bookmarked ? theme.palette.black.light1 : 'none',
  color: bookmarked ? theme.palette.black.light1 : theme.palette.black.light2,
}));

const BookmarkButton = styled.button({
  display: 'flex',
  alignItems: 'center',
});