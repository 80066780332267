import { memo, useMemo } from 'react';
import { InteractionType } from '@/enums';
import type { Chat } from '@/types';
import { toTitleCase } from '@/utils';
import { CitationsTable } from './Citation.Table';

type CrmNoteProps<T extends InteractionType = InteractionType> = {
  data: Chat.CrmNoteCitationMetadata<T>;
};

export const CrmNote = memo(({ data }: CrmNoteProps) => {

  switch (data.type) {
    case InteractionType.CallCenterInteraction:
      return <CallCenterInteraction data={data} />;
    case InteractionType.MedicalInsight:
      return <MedicalInsight data={data} />;
    case InteractionType.DocumentedInterest:
      return <DocumentedInterest data={data} />;
    case InteractionType.FbmsInteraction:
      return <FbmsInteraction data={data} />;
    default:
      throw new UnreachableCaseError(data);
  }
});

const FbmsInteraction = memo(({ data }: CrmNoteProps<InteractionType.FbmsInteraction>) => {

  const items = useMemo(() => {
    const educationGapValue = data.munQuestion1
      ? data.munQuestion3
        ? `${data.munQuestion1} - ${data.munQuestion3}`
        : data.munQuestion1
      : null;

    return [
      {
        name: 'MSL',
        value: data.accountName,
      },
      {
        name: 'HCP Name',
        value: data.kolName,
      },
      {
        name: 'Product',
        value: data.productName,
      },
      {
        name: 'Franchise',
        value: data.franchise,
      },
      educationGapValue && {
        name: 'Education Gap',
        value: educationGapValue,
      },
      {
        name: 'Source',
        value: data.source,
      },
      {
        name: 'Table',
        value: data.table,
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

const CallCenterInteraction = memo(({ data }: CrmNoteProps<InteractionType.CallCenterInteraction>) => {

  const items = useMemo(() => {
    return [
      {
        name: 'HCP Name',
        value: data.kolName,
      },
      {
        name: 'Product',
        value: data.productName,
      },
      {
        name: 'Topic',
        value: data.therapeuticArea,
      },
      data.interactionSubType === 'MIR' && {
        name: 'Request',
        value: data.request,
      },
      {
        name: 'Source',
        value: `${data.src} (${data.subSrc})`,
      },
      data.category && {
        name: 'Category',
        value: data.category + (data.subCategory ? ` (${data.subCategory})` : ''),
      },
      {
        name: 'Table',
        value: data.table,
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

const DocumentedInterest = memo(({ data }: CrmNoteProps<InteractionType.DocumentedInterest>) => {

  const items = useMemo(() => {
    return [
      {
        name: 'MSL',
        value: data.accountName,
      },
      {
        name: 'HCP Name',
        value: data.kolName,
      },
      {
        name: 'Product',
        value: data.productName,
      },
      {
        name: 'Interest Areas',
        value: data.interestAreas,
      },
      {
        name: 'Interest Verbatim',
        value: data.interest,
      },
      {
        name: 'Source',
        value: data.source,
      },
      {
        name: 'Table',
        value: data.table,
      },
    ];
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});

const MedicalInsight = memo(({ data }: CrmNoteProps<InteractionType.MedicalInsight>) => {

  const items = useMemo(() => {
    return [
      {
        name: 'MSL',
        value: data.accountName,
      },
      {
        name: 'HCP Name',
        value: data.kolName,
      },
      {
        name: 'Topics',
        value: [data.therapeuticArea, data.therapeuticSubArea].join(', '),
      },
      {
        name: 'Product',
        value: data.product,
      },
      {
        name: 'Sentiment',
        value: toTitleCase(data.sentiment),
      },
      {
        name: 'Insight',
        value: data.note,
      },
      {
        name: 'Source',
        value: data.source,
      },
      {
        name: 'Table',
        value: data.table,
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <CitationsTable items={items} />
  );
});