import { keyframes } from '@emotion/react';
import Color from 'color';

export const brightness = (hex: string, pct: number) => {

  const prefixed = hex.startsWith('#');

  const value = prefixed
    ? hex.slice(1)
    : hex;

  const [r1, g1, b1] = hexToRGBArray(value);

  const r = numToHex(r1 + pct);
  const g = numToHex(g1 + pct);
  const b = numToHex(b1 + pct);

  const unprefixed = `${r}${g}${b}`;

  return prefixed
    ? `#${unprefixed}`
    : unprefixed;
};

const clamp = (value: number) => {
  return value > 255
    ? 255
    : value < 0
      ? 0
      : value;
};

const numToHex = (value: number) => {
  const hex = clamp(value).toString(16);

  return hex.length === 1
    ? `0${hex}`
    : hex;
};

export const isDark = (hex: string) => {
  const prefixed = hex.startsWith('#');

  const value = prefixed
    ? hex.slice(1)
    : hex;

  const red = parseInt(value.substring(0, 2), 16);
  const green = parseInt(value.substring(2, 4), 16);
  const blue = parseInt(value.substring(4, 6), 16);
  const brightness = red * 0.299 + green * 0.587 + blue * 0.114;

  return brightness <= 180;
};

const hexToRGBArray = (hex: string) => {
  const prefixed = hex.startsWith('#');

  const value = prefixed
    ? hex.slice(1)
    : hex;

  const r = parseInt(value.substring(0, 2), 16);
  const g = parseInt(value.substring(2, 4), 16);
  const b = parseInt(value.substring(4, 6), 16);

  return [r, g, b] as const;
};

const R = 92;
const G = 113;
const B = 175;

//const full = `rgba(${R}, ${G}, ${B}, 1)`;
const dim = (r: number, g: number, b: number) => `rgba(${r}, ${g}, ${b}, 0.7)`;
const none = `rgba(${R}, ${G}, ${B}, 0)`;

export const pulse = (hex: string) => {
  const [r, g, b] = typeof hex === 'string'
    ? Color(hex).rgb().array()
    : [R, G, B];


  return keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${dim(r, g, b)};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px ${none};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${none};
    }
  `;
};