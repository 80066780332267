import { useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { ChatResponsePane } from '@/components/Chat/ResponsePane';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { MessageQueryHintContainer, MessageQueryHint } from '@/components/presentation';
import { ChatHintType } from '@/enums/chat';
import { useToggle } from '@/hooks/useToggle';
import type { Chat } from '@/types';

type Props = {
  className?: string;
  hints: Chat.QueryHint.Item[];
};

export const MessageQueryHints = ({ className, hints }: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);
  const [disabled] = useState<boolean>(false);

  const handleHint = useCallback((hint: Chat.QueryHint.Item) => () => {
    if (hint.type === ChatHintType.Text) {
      dispatch({
        type: 'input-changed',
        payload: { value: hint.displayValue },
      });
    }
  }, [dispatch]);

  return (
    <MessageQueryHintContainer className={className}>
      {hints.map((hint, i) => (
        <MessageQueryHint
          key={`${i}`}
          hint={hint}
          onClick={handleHint(hint)}
          disabled={disabled || hint.disabled} />
      ))}
    </MessageQueryHintContainer>
  );
};

export const RelatedQuestionsPane = (props: Props) => {
  const [open, toggleOpen] = useToggle(true);

  return (
    <ChatResponsePane
      className={props.className}
      open={open}
      toggleOpen={toggleOpen}
      title="Related Questions">
      <PaneHints hints={props.hints} />
    </ChatResponsePane>
  );
};

const PaneHints = styled(MessageQueryHints)({
  minHeight: 0,
  marginTop: 10,
  transition: `height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
});