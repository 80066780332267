import { memo } from 'react';
import styled from '@emotion/styled';
import { ChatCitationType } from '@/enums';

type Props = {
  isOpen: boolean;
  setOpen: () => void;
  type: ChatCitationType;
};

export const ToggleCitation = memo(({ isOpen, setOpen, type }: Props) => {

  const subjectMap: Partial<Record<ChatCitationType, string>> = {
    [ChatCitationType.CrmNote]: 'Interaction',
    [ChatCitationType.StrategicImperative]: 'Strategy Details',
    [ChatCitationType.KolProfile]: 'Profile',
  };

  const text = [
    isOpen ? 'Hide' : 'Show',
    subjectMap[type],
  ].filter(Boolean).join(' ');

  return (
    <StyledActionButton
      onClick={setOpen}>
      [{text}]
    </StyledActionButton>
  );
});

export const StyledActionButton = styled.div(({ theme }) => ({
  marginLeft: 5,
  cursor: 'pointer',
  color: theme.palette.hyperlink,
  fontFamily: theme.fonts.semiBold,
  fontSize: 13,
}));