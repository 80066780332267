import { memo } from 'react';
import { cx } from '@/utils';
import styles from './style/Progress.Time.module.css';
import { formatTimestamp } from './utils';

type Props = {
  className?: string;
  currentTime: number;
  duration: number;
};

export const AudioPlayerTimeProgress = memo(({ className, currentTime, duration }: Props) => {

  return (
    <div className={cx(styles.duration, className)}>
      {`${formatTimestamp(currentTime)} / ${formatTimestamp(duration)}`}
    </div>
  );
});

export default AudioPlayerTimeProgress;