import { useCallback, useEffect, useState, useRef } from 'react';
import SpeechRecognition from 'react-speech-recognition';
import { useSpeechRecognition as useSpeechRecLib } from 'react-speech-recognition';
import { RecordingEnd } from '@/components/Sounds/RecordingEnd';
import { RecordingStart } from '@/components/Sounds/RecordingStart';

type Props = {
  onResult: (result: string) => void;
  onAudioLevelChange: (level: number) => void;
};

export type MicState = 'inactive' | 'waiting-for-permission' | 'listening' | 'error';

const SPEECH_TIMEOUT_MS = 7000;

export const useSpeechRecognition = ({ onResult: onResultProp, onAudioLevelChange }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { listening: libListening, finalTranscript, resetTranscript, interimTranscript, transcript } = useSpeechRecLib({ clearTranscriptOnListen: true });
  const [micState, setMicState] = useState<MicState>('inactive');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (!libListening && finalTranscript) {
      onResultProp(finalTranscript);
      resetTranscript();
    }
  }, [libListening, finalTranscript, resetTranscript, onResultProp]);

  useEffect(() => {
    const api = SpeechRecognition.getRecognition();
    function onStart(e: SpeechRecognitionEvent) {
      console.log(e);
      setMicState('listening');
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => SpeechRecognition.stopListening(), SPEECH_TIMEOUT_MS);
      RecordingStart.play();
    }

    function onEnd(e: SpeechRecognitionEvent) {
      console.log(e);
      setMicState(old => {
        if (old === 'error') {
          return old;
        } else {
          RecordingEnd.play();
          return 'inactive';
        }
      });
    }

    function onError(e: SpeechRecognitionErrorEvent) {
      console.log(e);
      setMicState('error');
      if (e.error === 'not-allowed') {
        setErrorMsg('Permission denied');
      } else {
        setErrorMsg(e.error);
      }
    }

    if (api) {
      api.addEventListener('start', onStart);
      api.addEventListener('end', onEnd);
      api.addEventListener('error', onError);

      return () => {
        api.removeEventListener('start', onStart);
        api.removeEventListener('end', onEnd);
        api.removeEventListener('error', onError);
      };
    }
  }, []);

  useEffect(() => {
    if (micState === 'listening') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => SpeechRecognition.stopListening(), SPEECH_TIMEOUT_MS);
    }
  }, [interimTranscript, micState]);

  useEffect(() => {
    if (libListening) {
      document.body.addEventListener('click', clickHandler);

      return () => document.body.removeEventListener('click', clickHandler);
    }

    function clickHandler(e: MouseEvent) {
      if ((e.target as HTMLElement).tagName.toLowerCase() === 'button') {
        SpeechRecognition.stopListening();
      }
    }
  }, [libListening]);

  useEffect(() => {
    const api = SpeechRecognition.getRecognition();
    let audioContext: AudioContext;
    let mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
    let timer: NodeJS.Timeout;
    let micStream: MediaStream;

    if (api) {
      api.addEventListener('audiostart', onAudioStart);
      api.addEventListener('audioend', onAudioEnd);

      return () => {
        api.removeEventListener('audiostart', onAudioStart);
        api.removeEventListener('audioend', onAudioEnd);
      };
    }

    function onAudioStart() {
      window.navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        micStream = stream;
        audioContext = new AudioContext();
        mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
        const analyserNode = audioContext.createAnalyser();
        analyserNode.smoothingTimeConstant = 0.5;
        analyserNode.fftSize = 512;
        analyserNode.minDecibels = -80;

        mediaStreamAudioSourceNode.connect(analyserNode);

        const sampleArray = new Uint8Array(analyserNode.frequencyBinCount);

        const logTime = () => {

          analyserNode.getByteFrequencyData(sampleArray);
          let values = 0;

          const length = sampleArray.length;
          for (let i = 0; i < length; i++) {
            values += sampleArray[i];
          }

          const volume = Math.min(21, Math.max(0, 14 * Math.log10(values / length)));

          onAudioLevelChange(volume);
        };

        if (timer) {
          clearInterval(timer);
        }
        timer = setInterval(logTime, 100);
      }).catch(e => console.log(e));
    }

    function onAudioEnd() {
      if (mediaStreamAudioSourceNode) {
        mediaStreamAudioSourceNode.disconnect();
        mediaStreamAudioSourceNode = null;
      }

      if (audioContext) {
        audioContext.close();
        audioContext = null;
      }

      if (timer) {
        clearInterval(timer);
        timer = null;
      }

      if (micStream) {
        for (const track of micStream.getTracks()) {
          track.stop();
        }

        micStream = null;
      }
    }
  }, [onAudioLevelChange]);

  const start = useCallback(() => {
    if (micState !== 'error') {
      setMicState('waiting-for-permission');
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [micState]);

  useEffect(() => {
    console.log(micState);
  }, [micState]);

  return {
    listening: micState === 'listening',
    errorMsg,
    micState,
    interimResult: transcript,
    start,
    stop: SpeechRecognition.stopListening,
    isSupported: SpeechRecognition.browserSupportsSpeechRecognition,
  };
};