import { WorkbookDataContext, WorkbookDataLoadingContext } from './Context';
import { useFetchWorkbookData } from './hooks';

type Props = {
  url: string;
} & ChildrenProps;

export const WorkbookContainer = ({ children, url }: Props) => {

  const query = useFetchWorkbookData(url);

  return (
    <WorkbookDataContext.Provider value={query.data}>
      <WorkbookDataLoadingContext.Provider value={query.isInitialLoading}>
        {children}
      </WorkbookDataLoadingContext.Provider>
    </WorkbookDataContext.Provider>
  );
};

export default WorkbookContainer;