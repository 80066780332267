import type { RefObject } from 'react';
import { useCallback, useMemo, useState, useRef } from 'react';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import type { LoadingProcessData } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import annotationLayerCss from 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import textLayerCss from 'react-pdf/dist/esm/Page/TextLayer.css';
import { ProgressOverlay } from '@/components/presentation/ProgressOverlay';
import { useMeasure, useUseableCSS } from '@/utils/hooks';
import styles from './style.css';

type Props = {
  url: string;
  page?: number;
};

export const Pdf = (props: Props) => {
  const [progress, setProgress] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>();
  const [containerRef, bounds] = useMeasure();

  // useUseableCSS(textLayerCss);
  useUseableCSS(annotationLayerCss);

  const handleProgress = useCallback(({ loaded, total }: LoadingProcessData) => {
    setProgress((loaded / total) * 100);
  }, []);

  const handleSuccess = useCallback((value: PDFDocumentProxy) => {
    setProgress(100);
    setTotalPages(value.numPages);
  }, []);

  const handleError = useCallback((error: Error) => {
    console.log(error);
  }, []);

  const handlePageLoaded = useCallback((page: number, ref: RefObject<HTMLDivElement>) => () => {
    if (page === props.page) {
      ref.current.scrollIntoView();
    }
  }, [props.page]);

  const totalPagesArray = useMemo(() => new Array(totalPages), [totalPages]);

  return (
    <div
      ref={containerRef}
      className={styles.container}>
      <Document
        file={props.url}
        loading={<Loading progress={progress} />}
        options={{
          cMapUrl: 'static/pdfjs/cmaps/',
          cMapPacked: true,
          standardFontDataUrl: 'static/pdfjs/standard_fonts/',
        }}
        onLoadError={handleError}
        onLoadSuccess={handleSuccess}
        onLoadProgress={handleProgress}>
        <>
          {Array.from(totalPagesArray, (_, i) => (
            <PageRenderer
              key={i}
              page={i + 1}
              width={bounds.width}
              onLoadSuccess={handlePageLoaded} />
          ))}
        </>
      </Document>
    </div>
  );
};

type RenderPageProps = {
  page: number;
  width: number;
  onLoadSuccess?: (page: number, ref: RefObject<HTMLDivElement>) => () => unknown;
};

const PageRenderer = ({ page, width, onLoadSuccess }: RenderPageProps) => {
  const ref = useRef<HTMLDivElement>();
  return (
    <Page
      inputRef={ref}
      key={`page_${page}`}
      className={styles.page}
      loading={<PageLoading />}
      onLoadSuccess={onLoadSuccess(page, ref)}
      pageNumber={page}
      width={width} />
  );
};

type LoadingProps = {
  progress: number;
};

const Loading = ({ progress }: LoadingProps) => (
  <div className={styles.progress}>
    <ProgressOverlay progress={progress} />
  </div>
);

const PageLoading = () => (
  <div className={styles.pageLoading}>
    <ProgressOverlay progress={75} />
  </div>
);