import { useCallback, useMemo, useState } from 'react';
import { BrandInsightsModalContext } from '@/components/BrandInsights/context';
import { ChatPageContextDataContext, DispatchPageContextDataContext } from '@/components/Chat/context';
import { useAppReadyState } from '@/containers/AppReadyState';
import { ChatWidgetContainer } from '@/containers/Chat.Widget/Chat.Widget.Container';
import type { BrandInsightsPageContext } from '@/types';
import { useBrandInsightsAuthor, useBrandInsightsCapabilities } from './hooks';

type Props = ChildrenProps;

const ChatManagementContainer = (props: Props) => {

  const [contextData, setContextData] = useState<BrandInsightsPageContext.Item>(null);

  const author = useBrandInsightsAuthor();
  const capabilities = useBrandInsightsCapabilities();

  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => setOpen(prev => !prev), []);

  const enabled = true;

  const modalCtx = useMemo(() => [open, handleToggle] as [boolean, () => void], [open, handleToggle]);

  return (
    <BrandInsightsModalContext.Provider value={modalCtx}>
      <DispatchPageContextDataContext.Provider value={setContextData}>
        <ChatPageContextDataContext.Provider value={contextData}>
          {enabled && <ChatWidgetContainer author={author} capabilities={capabilities} />}
          {props.children}
        </ChatPageContextDataContext.Provider>
      </DispatchPageContextDataContext.Provider>
    </BrandInsightsModalContext.Provider>
  );
};

export const BrandInsightsContainer = (props: Props) => {
  const app = useAppReadyState();

  const initialized = app.initialized && app.authenticated;

  if (!initialized) {
    return (
      <>
        {props.children}
      </>
    );
  }

  return (
    <ChatManagementContainer>
      {props.children}
    </ChatManagementContainer>
  );
};