import type { CSSProperties } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type { UseSliderOptions } from '@/components/Slider/interfaces';
import { calculateNextStep, convertToPct } from '@/components/Slider/utils';
import type { DragEvent } from '@/utils/hooks';
import { useDrag } from '@/utils/hooks';

export const useSlider = ({
  maxValue,
  minValue,
  onDragStart,
  onDragEnd,
  onPositionChange,
  incrementBy,
  value,
}: UseSliderOptions) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);

  const incrementByPct = useMemo(() => {
    return (100 / (maxValue - minValue)) * incrementBy;
  }, [
    incrementBy,
    maxValue,
    minValue,
  ]);

  const completionPct = useMemo(() => {
    if (!value && value !== 0) {
      return 50;
    }

    return convertToPct({
      maxValue,
      minValue,
      value,
    });
  }, [maxValue, minValue, value]);

  const moveThumbToPoint: DragEvent = useCallback(point => {
    const progressBar = sliderRef.current.getBoundingClientRect();

    const step = calculateNextStep({
      dragHandlePosition: point?.x,
      leftBound: progressBar.x,
      incrementByPct,
      width: progressBar.width,
    });

    if (onPositionChange) {
      onPositionChange((step * incrementBy) + minValue);
    }
  }, [minValue, incrementBy, incrementByPct, onPositionChange]);

  const handleDrag: DragEvent = useCallback(point => {
    moveThumbToPoint(point);
  }, [moveThumbToPoint]);

  const [isDragging, handleMouseDown, handleTouchStart] = useDrag({
    onDrag: handleDrag,
    onDragEnd,
    onDragStart,
  });

  const mouseDown = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    moveThumbToPoint(e.nativeEvent);
    handleMouseDown(e);
  }, [handleMouseDown, moveThumbToPoint]);

  const thumbStyles: CSSProperties = useMemo(() => {
    const thumbWidth = thumbRef.current?.offsetWidth || 22;
    return {
      position: 'absolute',
      left: `calc(${completionPct}% - ${thumbWidth / 2}px)`,
      transition: isDragging ? '' : 'left .5s',
    };
  }, [completionPct, isDragging]);

  const activeTrackStyles: CSSProperties = useMemo(() => {
    const sliderWidth = sliderRef.current?.clientWidth || 0;
    const width = (completionPct / 100) * sliderWidth;

    return {
      width: `${width}px`,
      transition: isDragging ? '' : 'width .5s',
    };
  }, [completionPct, isDragging]);

  return {
    activeTrackStyles,
    handleMouseDown: mouseDown,
    handleTouchStart,
    thumbStyles,
    isDragging,
    sliderRef,
    thumbRef,
  } as const;
};

export default useSlider;