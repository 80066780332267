import { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { ChatContainer } from '@/components/BrandInsights/Chat.Container';
import { ChatViewType, ChatViewTypeContext } from '@/components/BrandInsights/context';
import { LinkStateContainer } from '@/components/BrandInsights/Link.Container';
import { ChatPageContextDataContext, DispatchPageContextDataContext } from '@/components/Chat/context';
import { ChatHistoryManagerContext } from '@/components/Chat.History/context';
import { ChatStateContainer } from '@/components/Chat.State';
import { buildDefaultChatContext } from '@/components/Chat.State/state.initial-state';
import { useChatSettings } from '@/components/Chat.State/useChatSettings';
import { ContextItemParam } from '@/containers/Chat.Widget/context-item';
import type { ChatContextType } from '@/enums/chat';
import type { BrandInsightsCapabilities, Chat } from '@/types';
import type { BrandInsightsPageContext } from '@/types';

type Props = {
  author: Chat.Creator;
  capabilities: BrandInsightsCapabilities;
} & ChildrenProps;

type RouteParams = {
  chatIdentifier: string;
};

export const ChatScreenContainer = (props: Props) => {
  const [queryparam] = useQueryParam('ctx', ContextItemParam);

  const settings = useChatSettings();

  const [contextData, setContextData] = useState<BrandInsightsPageContext.Item>(initializeContextData(queryparam, settings.defaultContextType));

  return (
    <ChatViewTypeContext.Provider value={ChatViewType.Page}>
      <DispatchPageContextDataContext.Provider value={setContextData}>
        <ChatPageContextDataContext.Provider value={contextData}>
          <ChatStateContainer>
            <ChatQueryParamContextContainer>
              <ChatIdentifierContainer>
                <ChatContainer author={props.author} capabilities={props.capabilities}>
                  {props.children}
                </ChatContainer>
              </ChatIdentifierContainer>
            </ChatQueryParamContextContainer>
          </ChatStateContainer>
        </ChatPageContextDataContext.Provider>
      </DispatchPageContextDataContext.Provider>
    </ChatViewTypeContext.Provider>
  );
};

function ChatIdentifierContainer(props: ChildrenProps) {
  const { chatIdentifier } = useParams<RouteParams>();

  return (
    <LinkStateContainer initialChatIdentifier={chatIdentifier}>
      {props.children}
    </LinkStateContainer>
  );
}

const ChatQueryParamContextContainer = (props: ChildrenProps) => {
  const [queryparam, setQueryParam] = useQueryParam('ctx', ContextItemParam);
  const { initialized } = useContext(ChatHistoryManagerContext);
  const contextData = useContext(ChatPageContextDataContext);

  useEffect(() => {
    if (initialized && contextData && queryparam && JSON.stringify(contextData) === JSON.stringify(queryparam)) {
      setQueryParam(undefined);
    }

  }, [
    contextData,
    initialized,
    queryparam,
    setQueryParam,
  ]);

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

function initializeContextData(qp: BrandInsightsPageContext.Item | null, defaultContextType: ChatContextType): BrandInsightsPageContext.Item {
  if (!qp) {
    return buildDefaultChatContext(defaultContextType);
  }

  return qp;
}