import { memo, useContext, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Plus as PlusIcon, Settings as SettingsIcon } from 'react-feather';
import { SideBarExpandedContext } from '@/components/BrandInsights/context';
import { ActiveChatSessionContext, ChatPageContextDataContext, ChatSettingsToggleContext } from '@/components/Chat/context';
import { useIsFullPageChat } from '@/components/Chat/hooks';
import { ChatHistory, SidebarArrowToggler, ChatHistoryItemsContext, ChatHistoryManagerContext, ChatHistorySharedContext } from '@/components/Chat.History';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { HistoryChatCount } from '@/components/presentation';
import { Constants } from '@/components/Theme';
import { shouldForwardProp } from '@/utils/emotion';

export const ChatSidebar = () => {
  const pageContext = useContext(ChatPageContextDataContext);
  const chat = useContext(ActiveChatSessionContext);
  const [isExpanded, setIsExpanded] = useContext(SideBarExpandedContext);
  const items = useContext(ChatHistoryItemsContext);
  const shared = useContext(ChatHistorySharedContext);
  const historyManager = useContext(ChatHistoryManagerContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const isFullpageChat = useIsFullPageChat();

  const toggleExpanded = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, [setIsExpanded]);

  const handleNewChat = useCallback(() => {
    dispatch({
      type: 'new-chat-started',
      payload: {
        context: chat?.context || pageContext,
      },
    });
  }, [
    chat?.context,
    pageContext,
    dispatch,
  ]);

  const historyCount = useMemo(() => items.length + shared.length, [items.length, shared.length]);

  return (
    <Root>
      <Wrap isExpanded={isExpanded} isFullPageChat={isFullpageChat}>
        <Content>
          <Collapsed isExpanded={isExpanded}>
            <CollapsedHeader>
              <NewChatButtonCollapsed
                onClick={handleNewChat}>
                <StyledCollapsedPlusIcon />
              </NewChatButtonCollapsed>
            </CollapsedHeader>
            <CountWrapper>
              {!historyManager.loading && (
                <StyledHistoryChatCount
                  onClick={toggleExpanded}
                  count={historyCount} />
              )}
            </CountWrapper>
          </Collapsed>
          <Expanded isExpanded={isExpanded}>
            {isFullpageChat && (
              <ExpandedHeader>
                <Text>Conversations</Text>
                <NewChatButtonCollapsed
                  isFullPageChat={isFullpageChat}
                  onClick={handleNewChat}>
                  <StyledCollapsedPlusIcon />
                </NewChatButtonCollapsed>
              </ExpandedHeader>
            )}

            {!isFullpageChat && (
              <ExpandedHeader>
                <NewChatButtonExpanded
                  onClick={handleNewChat}>
                  <StyledExpandedPlusIcon />
                  <NewChatTextExpanded>New Chat</NewChatTextExpanded>
                </NewChatButtonExpanded>
              </ExpandedHeader>
            )}

            <ChatHistory
              items={items}
              shared={shared} />
          </Expanded>
        </Content>

        <StyledToggler
          open={isExpanded}
          onClick={toggleExpanded} />
      </Wrap>
    </Root>
  );
};

const Root = styled.div`
  box-sizing: border-box;

  border-top-left-radius: ${Constants.Modal.BorderRadius}px;
  border-bottom-left-radius: ${Constants.Modal.BorderRadius}px;

  padding: 15px 0;
  height: 100%;
  background: ${({ theme }) => theme.palette.gray.light2};
`;

const Settings = styled.div`
  position: absolute;
  bottom: 0;
  left: 20px;
  background-color: ${({ theme }) => theme.palette.gray.light1};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  cursor: pointer;
`;

export const EmptySidebar = memo(() => (
  <Root>
    <Wrap />
  </Root>
));

const Wrap = styled('div', { shouldForwardProp: shouldForwardProp('isFullPageChat') }) <BarProps>`
  position: relative;
  width: ${props => props.isExpanded ? (props.isFullPageChat ? '350' : Constants.Sidebar.ExpandedWidth) : Constants.Sidebar.Width}px;
  transition: width 500ms;
  height: 100%;
`;

const Text = styled.div`
  margin-right:auto;
  font-size: 28px;
  font-family: ${({ theme }) => theme.fonts.bold};
  margin-bottom: 10px;
`;

const Content = styled.div`
  box-sizing: border-box;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  height: 100%;
`;

const Expanded = styled.div<BarProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: ${props => props.isExpanded ? '0' : '-2000px'};
  opacity: ${props => props.isExpanded ? 1 : 0};
  transition: margin-left 400ms, width 499ms, opacity 900ms;
  position: absolute;

  height: 100%;
  width: 100%;
`;

const Collapsed = styled.div<BarProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: ${props => props.isExpanded ? '-500px' : '0'};
  opacity: ${props => props.isExpanded ? 0 : 1};
  transition: margin-right 500ms, width 499ms, opacity 900ms;
  position: absolute;

  height: 100%;
  width: 100%;
`;

const ExpandedHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 ${Constants.Sidebar.HorizontalPadding}px;
  margin-bottom: 15px;
  align-items: center;
`;

const CollapsedHeader = styled.div`
  margin-bottom: 15px;
`;

const StyledCollapsedPlusIcon = styled(PlusIcon)`
  color: ${props => props.theme.palette.white.main};
  stroke-width: 3;
  width: 40;
  min-width: 40;
`;

const StyledExpandedPlusIcon = styled(PlusIcon)`
  color: ${props => props.theme.palette.secondary.main};
  stroke-width: 3;
  width: 24;
  min-width: 24;
`;
const NewChatButtonCollapsed = styled('button', { shouldForwardProp: shouldForwardProp('isFullPageChat') }) <BarProps>`

  margin-right: ${props =>
    props.isFullPageChat ? '5px' : '0px'};
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 50%;
  background-color:${({ theme }) => theme.palette.sentiment.primary.main};
  width: ${Constants.Sidebar.IconSize}px;
  height: ${Constants.Sidebar.IconSize}px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.palette.sentiment.primary.dark};
  }
  &:disabled {
    background-color: ${props => props.theme.palette.gray.light1};
    cursor: not-allowed;
  }
`;

const NewChatButtonExpanded = styled.button`
  border-radius: 25px;
  background-color:${({ theme }) => theme.palette.sentiment.primary.main};
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;

  &:disabled {
    background-color: ${props => props.theme.palette.gray.light1};
    cursor: not-allowed;
  }
`;

const NewChatTextExpanded = styled.div`
  color: ${props => props.theme.palette.secondary.main};
  font-family: ${props => props.theme.fonts.semiBold};

  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
`;

const CountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${Constants.Sidebar.IconSize}px;
  height: ${Constants.Sidebar.IconSize}px;
  box-sizing: border-box;
  margin-top:13px;
`;

const StyledHistoryChatCount = styled(HistoryChatCount)`
  cursor: pointer;
`;

const StyledToggler = styled(SidebarArrowToggler)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -${Constants.Sidebar.ArrowTogglerSize / 2}px;
`;

type BarProps = {
  isExpanded?: boolean;
  isFullPageChat?: boolean;
};