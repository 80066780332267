/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Markdown, { type Options as MarkdownOptions } from 'react-markdown';
import remarkFlexibleMarkers from 'remark-flexible-markers';
import remarkGfm from 'remark-gfm';
import { remarkCitations } from './remark.citations-plugin';
import { useMarkdownComponents } from './useMarkdownComponents';

type Props = {
  components?: MarkdownOptions['components'];
  value: string;
};

export const ChatMarkdown = (props: Props) => {
  const components = useMarkdownComponents(props.components);

  return (
    <Markdown
      css={markdownCss}
      remarkPlugins={[remarkGfm, remarkFlexibleMarkers, remarkCitations]}
      skipHtml={false}
      components={components}>
      {props.value}
    </Markdown>
  );
};

export const BasicMarkdown = (props: { children: string }) => {
  const components = useMarkdownComponents();

  return (
    <Markdown
      css={markdownCss}
      components={components}>
      {props.children}
    </Markdown>
  );
};

const markdownCss = css({
  whiteSpace: 'normal',
});