import styled from '@emotion/styled';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { StopCircle } from 'react-feather';
import { useReadAloud } from '@/hooks/useReadAloud';
import { useHasBetaPermission, useHasChatReadAloudEnabled } from '@/store/selectors';
import { BaseMessageButton } from './Message.Button';

type Props = {
  content: string;
};

export const ReadAloudButton = ({ content }: Props) => {
  const { isSpeaking, toggleSpeech } = useReadAloud();
  const hasBetaPermission = useHasBetaPermission();
  const hasChatReadAloudEnabled = useHasChatReadAloudEnabled();

  if (!hasBetaPermission || !hasChatReadAloudEnabled) return null;

  return (
    <StyledButton onClick={() => toggleSpeech(content)}>
      {isSpeaking ? <StopCircle size={18} /> : <PlayArrowOutlinedIcon fontSize="small" />}
      {isSpeaking ? 'Stop' : 'Read Aloud'}
    </StyledButton>
  );
};

const StyledButton = styled(BaseMessageButton)({
  display: 'flex',
  justifyContent: 'left',
  gap: '4px',
});