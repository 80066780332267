import { memo, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { ChatMarkdown } from '@/components/presentation/Markdown/Markdown';
import type { KolProfileDataType } from '@/enums/kol';
import { KolProfileDataTypeLabels, KolProfileDataTypeSourcesMap, KolDataSourceLabels, KolProfileDataTypeTables } from '@/enums/kol';
import type { Chat } from '@/types';
import { SnippetRoot, SnippetText } from './Citation.Snippet';
import { MessageCitationSnippetGroupSelector } from './Citation.SnippetGroupSelector';

type Props = {
  value: Chat.KolCitationMetadata;
};

export const HcpProfileCitation = memo(({ value }: Props) => {

  const [selectedSource, setSelectedSource] = useState<KolProfileDataType | null>(null);

  const selectedSourceItem = useMemo(() => {
    if (!selectedSource) return null;
    return value.sources.find(source => source.type === selectedSource);
  }, [selectedSource, value.sources]);

  const snippetText = useMemo(() => {
    if (!selectedSourceItem) return null;

    const dataSource = KolProfileDataTypeSourcesMap[selectedSourceItem.type];

    const tables: string[] = KolProfileDataTypeTables[selectedSourceItem.type];

    const values: string[] = [];

    values.push(`**Data Source**: ${KolDataSourceLabels[dataSource]}\n`);
    values.push(`**Last Updated On**: ${format(selectedSourceItem.lastUpdatedOn, 'MM/dd/yyyy')}\n`);
    if (tables.length) {
      values.push(`**Tables**`);
      for (const table of tables) {
        values.push(`* ${table}`);
      }
    }

    return values.join('\n');
  }, [selectedSourceItem]);

  return (
    <>
      <SourcesWrapper>
        {value.sources.map((source, index) => {
          const isSelected = selectedSource === source.type;
          return (
            <MessageCitationSnippetGroupSelector
              key={index}
              value={KolProfileDataTypeLabels[source.type]}
              selected={isSelected}
              onClick={() => isSelected ? setSelectedSource(null) : setSelectedSource(source.type)} />
          );
        })}
      </SourcesWrapper>
      {snippetText && (
        <StyledSnippetRoot>
          <SnippetText>
            <ChatMarkdown value={snippetText} />
          </SnippetText>
        </StyledSnippetRoot>
      )}
    </>
  );
});

const SourcesWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  margin-top: 10px;
`;

const StyledSnippetRoot = styled(SnippetRoot)({
  marginTop: 10,
});