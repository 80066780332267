import { SearchContainer } from '@/containers/WorkspaceGlobalSearch/SearchContainer';
import Main from './Search.Main';

export default function Search() {
  return (
    <SearchContainer>
      <Main />
    </SearchContainer>
  );
}

export { Search };