/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { AssignCitationRefContext, OpenCrmNoteContext, OpenSnippetContext, SetOpenCitationContext } from '@/components/Chat/context';
import { useDownloadKolProfile } from '@/components/Chat/hooks/useDownloadKolProfile';
import { CitationNumber } from '@/components/presentation/CitationNumber';
import { BasicMarkdown } from '@/components/presentation/Markdown/Markdown';
import { Tooltip } from '@/components/presentation/Tooltip';
import { Constants } from '@/components/Theme';
import type { InteractionType } from '@/enums';
import { ChatCitationType } from '@/enums';
import { ReportFormat } from '@/enums/reports';
import type { Chat } from '@/types/chat';
import { CrmNote } from './Citation.CrmNote';
import { HcpProfileCitation } from './Citation.HcpProfile';
import {
  AffiliationDetails,
  AssociationDetails,
  ClinicalLeaderTopicDetails,
  ClinicalTrialDetails,
  CollaborationDetails,
  CompanySponsorshipDetails,
  ConferenceDetails,
  ContactDetailsCitation,
  DigialLeaderTopicDetails,
  PublicationDetails,
  ScientificLeaderTopicDetails,
  UnmetNeedDetails,
} from './Citation.HcpProfile.Elements';
import { MessageCitationMaybeLink } from './Citation.MaybeLink';
import { MessageCitationScore } from './Citation.Score';
import { MessageCitationSnippet, SnippetRoot, SnippetText } from './Citation.Snippet';
import { MessageCitationSnippetGroupSelector } from './Citation.SnippetGroupSelector';
import { StyledActionButton, ToggleCitation } from './Citation.Toggle';
import { getSnippetLink, getSnippetDisplayValue, buildSnippetCharacters } from './utils';

type Props = {
  className?: string;
  item: Chat.Citation;
};

function buildCitationKey(item: Chat.Citation) {
  return `${item.type}-${item.id}`;
}

export const MessageCitation = ({ className, item }: Props) => {

  const ref = useRef<HTMLDivElement>(null);

  const [openSnippet, handleSnippetSelection] = useContext(OpenSnippetContext);
  const assignCitationRef = useContext(AssignCitationRefContext);

  const openCitation = useContext(OpenCrmNoteContext);
  const setOpenCitation = useContext(SetOpenCitationContext);

  const highestScore = useMemo(() => {
    if (!item.snippets.length) return null;
    return Math.max(...item.snippets.map(snippet => snippet.score || 0));
  }, [item.snippets]);

  const onSnippetSelected = useCallback((snippetId: string) => () => {
    handleSnippetSelection([buildCitationKey(item), snippetId]);
  }, [handleSnippetSelection, item]);

  const citationKey = useMemo(() => buildCitationKey(item), [item]);

  const selectedSnippet = useMemo(() => {
    if (!openSnippet) return null;
    if (citationKey !== openSnippet[0]) return null;

    return item.snippets.find(snippet => snippet.id === openSnippet[1]);
  }, [citationKey, item.snippets, openSnippet]);

  const setCitationRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const itemKey = buildCitationKey(item);
      assignCitationRef(itemKey, node);
      ref.current = node;
    }
  }, [assignCitationRef, item]);

  const isCitationOpen = useMemo(() => {
    return openCitation === item.id || openSnippet?.[0] === citationKey;
  }, [openCitation, item.id, openSnippet, citationKey]);

  const renderOpenCitation = useCallback(() => {
    if (item.type === ChatCitationType.KolProfile && item.metadata) {
      return (
        <HcpProfileCitation value={item.metadata as Chat.KolCitationMetadata} />
      );
    }

    if (!isCitationOpen) {
      return null;
    }

    if (item.type === ChatCitationType.CrmNote) {
      return (
        <CrmNote data={item.metadata as Chat.CrmNoteCitationMetadata} />
      );
    } else if (item.type === ChatCitationType.StrategicImperative) {
      return (
        <StyledSnippetRoot>
          <SnippetText>
            <BasicMarkdown>
              {(item.metadata as Chat.StrategicImperativeCitationMetadata).content}
            </BasicMarkdown>
          </SnippetText>
        </StyledSnippetRoot>
      );
    } else if (item.type === ChatCitationType.ContactDetail) {
      return (
        <ContactDetailsCitation data={item.metadata as Chat.CitationMetadata<ChatCitationType.ContactDetail>} />
      );
    }

    return null;
  }, [item.metadata, item.type, isCitationOpen]);

  const renderOpenSnippet = useCallback(() => {
    if (!selectedSnippet) return null;

    if (item.type === ChatCitationType.Publication) {
      return (
        <PublicationDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.Publication>} />
      );
    } else if (item.type === ChatCitationType.ClinicalTrial) {
      return (
        <ClinicalTrialDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.ClinicalTrial>} />
      );
    } else if (item.type === ChatCitationType.CongressAbstract) {
      return (
        <PublicationDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.Publication>} />
      );
    } else if (item.type === ChatCitationType.Association) {
      return (
        <AssociationDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.Association>} />
      );
    } else if (item.type === ChatCitationType.Affiliation) {
      return (
        <AffiliationDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.Affiliation>} />
      );
    } else if (item.type === ChatCitationType.Collaboration) {
      return (
        <CollaborationDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.Collaboration>} />
      );
    } else if (item.type === ChatCitationType.CompanySponsorship) {
      return (
        <CompanySponsorshipDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.CompanySponsorship>} />
      );
    } else if (item.type === ChatCitationType.Conference) {
      return (
        <ConferenceDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.Conference>} />
      );
    } else if (item.type === ChatCitationType.ScientificLeaderTopic) {
      return (
        <ScientificLeaderTopicDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.ScientificLeaderTopic>} />
      );
    } else if (item.type === ChatCitationType.ClinicalLeaderTopic) {
      return (
        <ClinicalLeaderTopicDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.ClinicalLeaderTopic>} />
      );
    } else if (item.type === ChatCitationType.DigitalLeaderTopic) {
      return (
        <DigialLeaderTopicDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.DigitalLeaderTopic>} />
      );
    } else if (item.type === ChatCitationType.UnmetNeed) {
      return (
        <UnmetNeedDetails data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.UnmetNeed>} />
      );
    } else if (item.type === ChatCitationType.CallCenterInteractions) {
      return (
        <CrmNote data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.CallCenterInteractions>} />
      );
    } else if (item.type === ChatCitationType.FbmsInteractions) {
      return (
        <CrmNote data={selectedSnippet.metadata as Chat.CitationSnippetMetadata<ChatCitationType.FbmsInteractions>} />
      );
    } else if (item.type === ChatCitationType.MedicalInsights) {
      return (
        <CrmNote data={selectedSnippet.metadata as Chat.CrmNoteCitationMetadata<InteractionType.MedicalInsight>} />
      );
    } else if (item.type === ChatCitationType.DocumentedInterests) {
      return (
        <CrmNote data={selectedSnippet.metadata as Chat.CrmNoteCitationMetadata<InteractionType.DocumentedInterest>} />
      );
    }

    return (
      <MessageCitationSnippet
        citationType={item.type}
        item={selectedSnippet}
        to={getSnippetLink(item, selectedSnippet)} />
    );
  }, [item, selectedSnippet]);

  const toggleCitationOpen = useCallback(() => {
    const value = isCitationOpen ? null : item.id;
    setOpenCitation(value);
    if (!value) {
      handleSnippetSelection(null);
    }
  }, [handleSnippetSelection, isCitationOpen, item.id, setOpenCitation]);

  return (
    <Root className={className} ref={setCitationRef}>
      <Wrap>
        <Header>
          {!!item.ordinal && <CitationOrdinal>{item.ordinal}</CitationOrdinal>}
          <Layers>
            <Layer1>
              <FlexRow>
                <StyledLink to={item.link}>
                  <Tooltip
                    title={item.title}
                    enterDelay={1000}
                    enterNextDelay={500}>
                    <Title>{item.title} {!!item.snippets.length && <>({item.snippets.length})</>}</Title>
                  </Tooltip>
                </StyledLink>
                {item.type === ChatCitationType.KolProfile && item.metadata && (
                  <DownloadProfile data={item.metadata as Chat.KolCitationMetadata} />
                )}
                {(
                  <ToggleCitation
                    type={item.type}
                    isOpen={isCitationOpen}
                    setOpen={toggleCitationOpen} />
                )}
              </FlexRow>
              {!!highestScore && <MessageCitationScore score={highestScore} />}
            </Layer1>
            <Layer2>
              {renderOpenCitation()}
              {isCitationOpen && !!item.snippets.length && (
                <SnippetGroupSelectorContainer>
                  {item.snippets.map((snippet, i) => {
                    const isSelected = selectedSnippet?.id === snippet.id;
                    const displayValue = getSnippetDisplayValue(snippet, item.type);
                    return (
                      <SnippetWrapper key={i}>
                        {!!snippet.ordinal && <SnippetOrdinal>{buildSnippetCharacters(snippet.ordinal)}</SnippetOrdinal>}
                        <MessageCitationSnippetGroupSelector
                          value={displayValue}
                          selected={isSelected}
                          onClick={onSnippetSelected(snippet.id)} />
                      </SnippetWrapper>
                    );
                  })}
                </SnippetGroupSelectorContainer>
              )}
            </Layer2>
          </Layers>
        </Header>
        {renderOpenSnippet()}
      </Wrap>
    </Root>
  );
};

type DownloadProfileProps = {
  data: Chat.KolCitationMetadata;
};

const DownloadProfile = ({ data }: DownloadProfileProps) => {

  const { download } = useDownloadKolProfile({
    kol: {
      id: data.kolId,
      name: data.kolName,
    },
  });

  const handleDownload = useCallback(() => {
    download({
      title: `Generating Export`,
      name: `${data.kolName} Profile`,
      extension: ReportFormat.Word,
    });
  }, [download, data.kolName]);

  return (
    <StyledActionButton
      onClick={handleDownload}>
      [Download Profile]
    </StyledActionButton>
  );
};

const Root = styled.div`
  box-sizing: border-box;
`;

const Wrap = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div({
  padding: `12px 5px`,
  display: 'flex',
  alignItems: 'flex-start',
});

const Layer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Layer2 = styled.div({});

const FlexRow = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const Title = styled.div`
  font-family: ${props => props.theme.fonts.semiBold};
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SnippetGroupSelectorContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  margin-top: 10px;
`;

const SnippetWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledLink = styled(MessageCitationMaybeLink)`
  width: calc(100% - ${Constants.Message.CitationScoreWidth + 5}px);
`;

const Layers = styled.div({
  width: 'calc(100% - 30px)',
  display: 'flex',
  flexDirection: 'column',
});

const CitationOrdinal = styled(CitationNumber)({
  marginRight: 10,
});

const SnippetOrdinal = styled(CitationOrdinal)({
  marginRight: 5,
});

const StyledSnippetRoot = styled(SnippetRoot)({
  marginTop: 10,
});