import { useCallback } from 'react';
import type { QueryFunction, QueryKey, UseBaseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export type UseQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseBaseQueryOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryFnData,
  TQueryKey
>, 'onError' | 'onSettled' | 'onSuccess'>
  & {
    onError?: (err: TError) => void;
    onSettled?: (data: TData | undefined, error: TError | null) => void;
    onSuccess?: (data: TData) => void;
  };

export const useQueryWrapper = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  queryFn,
  onError,
  onSettled,
  onSuccess,
  ...options
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) => {

  const queryFnWrapper: QueryFunction<TQueryFnData, TQueryKey> = useCallback(params => {
    try {
      const res = queryFn(params);
      if (res instanceof Promise) {
        return res.then(data => {
          onSuccess?.(data as unknown as TData);
          onSettled?.(res as unknown as TData, null);
          return data;
        }).catch(err => {
          onError?.(err);
          onSettled?.(undefined, err as TError);
          throw err;
        });
      } else {
        onSuccess?.(res as unknown as TData);
        onSettled?.(res as unknown as TData, null);
        return res;
      }
    } catch (error) {
      onError?.(error as TError);
      onSettled?.(undefined, error as TError);
      throw error;
    }
  }, [queryFn, onError, onSettled, onSuccess]);

  const query = useQuery({
    ...options,
    queryFn: queryFnWrapper,
  });

  return query;
};