import type { AxiosInstance } from 'axios';

export async function download(client: AxiosInstance, url: string) {
  return client.request<Blob>({ url, method: 'GET', responseType: 'blob' }).then(response => {
    const filename = new RegExp(/filename="(.+)"/).exec(response.headers['content-disposition']);
    return {
      blob: response.data,
      filename: filename.length ? filename[1] : null,
    };
  });
}