import { useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import type { WaveSurferParams } from 'wavesurfer.js/types/params';

export const useWaveSurfer = (params: WaveSurferParams) => {

  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer>();

  useEffect(() => {

    const ws = WaveSurfer.create(params);

    ws.on('error', e => {
      console.warn(e);
    });

    setWaveSurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [params]);

  return waveSurfer;
};