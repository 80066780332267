import { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as $api from '@/api/workspaces';
import { querykey } from '@/consts';
import { useQueryDownloader } from '@/containers/QueryDownloader';
import { WorkspaceFileSummaryContext } from './Context';

type Props = {
  fileId: number;
} & ChildrenProps;

export function SummaryContainer(props: Props) {

  const [isRegenerating, setIsRegenerating] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: querykey.Workspaces.FileSummary.Get({ fileId: props.fileId }),
    queryFn: () => {
      return $api.getFileSummary({
        fileId: props.fileId,
      });
    },
    onSuccess: data => {
      if (data?.summary?.status === 'pending') {
        setIsRegenerating(true);
      } else {
        setIsRegenerating(false);
      }
      return data;
    },
  });

  const regenerateMutation = useMutation(querykey.Workspaces.FileSummary.Post({ fileId: props.fileId }), () => {
    return $api.regenerateFileSummary({
      fileId: props.fileId,
    });
  }, {
    onMutate: () => {
      setIsRegenerating(true);
    },
    onSuccess: () => {
      refetch();
    },
  });

  const handleRegenerate = useCallback(() => {
    return regenerateMutation.mutateAsync();
  }, [regenerateMutation]);

  const downloader = useQueryDownloader({
    queryKey: [
      `brand-insights:chat:download`,
      props.fileId,
    ],
    queryFn: ({ queryKey: [, fileId] }) => {
      return $api.downloadFileSummary({ fileId });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

  const handleDownload = useCallback(() => {
    return downloader.download({
      title: `Generating Export`,
      name: `File Summary`,
      extension: 'docx',
    });
  }, [downloader]);

  const value = {
    summary: data?.summary,
    isLoading,
    download: handleDownload,
    refetch,
    regenerate: handleRegenerate,
    isRegenerating,
  };

  return (
    <WorkspaceFileSummaryContext.Provider value={value}>
      {props.children}
    </WorkspaceFileSummaryContext.Provider>
  );
}

export default SummaryContainer;