import { useCallback, useMemo } from 'react';
import useSearchFiltersQuery from '@/containers/WorkspaceGlobalSearch/hooks/useSearchFilterQuery';
import type { WorkspaceSearchFull } from '@/types';
import { useReducedFilterState } from './hooks/userReducedFilterState';
import FiltersCheckbox from './Search.Main.Filters.Checkbox';
import styles from './style/Main.Filters.File.module.css';

export const SearchMainFiltersFile = () => {
  const [, setQuery] = useSearchFiltersQuery();
  const filters = useReducedFilterState();

  const handleChange = useCallback((fileType: WorkspaceSearchFull.File.FileTypes, checked: boolean) => {
    if (checked) {
      setQuery({ filetypes: Array.from([...filters.filetypes, fileType]) });
    } else {
      setQuery({ filetypes: filters.filetypes.filter(value => value !== fileType) });
    }
  }, [filters.filetypes, setQuery]);

  const handleClear = useCallback(() => {
    setQuery({ filetypes: [] }, 'replaceIn');
  }, [setQuery]);

  const showClear = useMemo(() => filters.filetypes.length > 1, [filters.filetypes]);

  const showFileTypes: Array<{ fileType: WorkspaceSearchFull.File.FileTypes; label: string }> = useMemo(() => [
    { fileType: 'pptx', label: 'Powerpoint' },
    { fileType: 'docx', label: 'Word' },
    { fileType: 'xlsx', label: 'Excel' },
    { fileType: 'pdf', label: 'PDF' },
    // { fileType: 'video',   label: 'Video' },
    // { fileType: 'image',   label: 'Image' },
    // { fileType: 'archive', label: 'Archive' },
    { fileType: 'other', label: 'Other' },
  ], []);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>File Type</div>
        {showClear && <div className={styles.clear} onClick={handleClear}>Clear</div>}
      </div>
      <div className={styles.fileTypes}>
        {showFileTypes.map(item => (
          <FileTypeComponent
            key={item.fileType}
            selected={filters.filetypes}
            fileType={item.fileType}
            onChange={handleChange}
            label={item.label} />
        ))}
      </div>
    </div>
  );
};

type FileTypeComponentProps = {
  selected: WorkspaceSearchFull.File.FileTypes[];
  fileType: WorkspaceSearchFull.File.FileTypes;
  label: string;
  onChange: (fileType: WorkspaceSearchFull.File.FileTypes, checked: boolean) => unknown;
};

const FileTypeComponent = ({ selected, fileType, onChange, label }: FileTypeComponentProps) => {
  return (
    <div className={styles.fileType}>
      <FiltersCheckbox
        className={styles.check}
        checked={selected.includes(fileType)}
        onChange={(_, checked) => onChange(fileType, checked)}
        label={label} />
    </div>
  );
};