import { Folder } from 'react-feather';
import type { CellProps } from 'react-table';
import type { FoldersTableItem } from './interfaces';
import ClickContainer from './ResultClickContainer';
import styles from './style/Cell.Name.module.css';

export default function Name({ row: { original: data } }: CellProps<FoldersTableItem>) {
  return (
    <ClickContainer className={styles.overflowWrapper} item={data}>
      <div className={styles.root}>
        <Folder
          className={styles.icon}
          color="#737373"
          size={28} />
        <div className={styles.details}>
          <div className={styles.name}>{data.name}</div>
        </div>
      </div>
    </ClickContainer>
  );
}

export { Name };