import { memo } from 'react';

type Props = {
  className?: string;
  color?: string;
  size?: number;
  strokeWidth?: number;
};

export const InsightsChatIcon = memo(({ className, color = 'currentColor', size = 24, strokeWidth = 1.5 }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
    >
      <g
        id="Chat"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M16 1C18.209 1 20.209 1.89543 21.657 3.34315C23.105 4.79086 24 6.79086 24 9V21L16.8174 17H9C6.79086 17 4.79086 16.1046 3.34315 14.6569C1.89543 13.2091 1 11.2091 1 9C1 6.79086 1.89543 4.79086 3.34315 3.34315C4.79086 1.89543 6.79086 1 9 1H16Z"
        />
        <path
          d="M12.5 4.75C13.2594 4.75 13.9469 5.0578 14.4445 5.55546C14.9422 6.05311 15.25 6.74061 15.25 7.5C15.25 8.25939 14.9422 8.94689 14.4445 9.44454C14.2759 9.61324 13.8909 10.0592 13.2922 10.7846H11.7078C11.1091 10.0592 10.7241 9.61324 10.5555 9.44454C10.0578 8.94689 9.75 8.25939 9.75 7.5C9.75 6.74061 10.0578 6.05311 10.5555 5.55546C11.0531 5.0578 11.7406 4.75 12.5 4.75Z"
        />
        <line x1="12" y1="13.3" x2="13.1" y2="13.3" />
      </g>
    </svg>
  );
});
