import type { ComponentType } from 'react';
import { useCallback, useState } from 'react';

type Props = {
  onClose: () => void;
  open: boolean;
};

export type BaseModalProps = Props;

type Options = {
  keepMounted?: boolean;
  defaultOpen?: boolean;
};

export const useModal = <T extends BaseModalProps = BaseModalProps, K = Omit<T, 'onClose' | 'open'>>(ModalComponent: ComponentType<Props & K>, options: Options = {}) => {
  const [visible, setVisibility] = useState(options.defaultOpen ?? false);

  const toggleVisibility = useCallback(() => {
    setVisibility(s => !s);
  }, []);

  const Modal = useCallback((props: K) => {
    if (!options.keepMounted && !visible) {
      return null;
    }

    return (
      <ModalComponent
        onClose={toggleVisibility}
        open={visible}
        {...props} />
    );
  }, [
    ModalComponent,
    options.keepMounted,
    toggleVisibility,
    visible,
  ]);

  return [toggleVisibility, Modal, visible] as const;
};