import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { cx } from '@/utils';
import styles from './style/Back.module.css';

export const Back = ({ className, ...props }: BackProps) => (
  <div
    className={cx(styles.root, className)}
    {...props}>
    Back
  </div>
);

export const BackLink = ({ className, ...props }: BackLinkProps) => (
  <Link {...props}>
    <Back className={className} />
  </Link>
);

export type BackProps = {
  className?: string;
  disabled?: boolean;
} & Partial<React.HTMLAttributes<HTMLDivElement>>;

export type BackLinkProps = {
  className?: string;
} & LinkProps;