import { useCallback, useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ActionMeta, MultiValue } from 'react-select';
import Select from 'react-select';
import * as $api from '@/api';
import { useTheme } from '@/components/Theme';
import type { BrandInsightsInstanceContext } from '@/types';
import type { Tag } from '@/types';
import type { TagFilter } from '@/types/chat.filters';
import * as Presentation from './Presentation';

type Props = {
  context: BrandInsightsInstanceContext.ItemWithMetadata;
  value: TagFilter.Item[];
  onChange: (value: TagFilter.Item[], meta: ActionMeta<TagFilter.Item>) => unknown;
};

export function Tag({ context, value, onChange }: Props) {
  const theme = useTheme();

  const selectStyles = useMemo(() => Presentation.buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => Presentation.buildSelectTheme(theme), [theme]);

  const selected = useMemo(() => value, [value]);

  const handleChange = useCallback((value: MultiValue<TagFilter.Item>, meta: ActionMeta<TagFilter.Item>) => {
    onChange(Array.from(value), meta);
  }, [onChange]);

  const [inputValue, setInputValue] = useState('');
  const optionsQuery = useQuery({
    queryKey: [`get:brand-insights/chat/filters/tags`, context, inputValue],
    queryFn: async () => {
      return $api.searchTags({
        context,
        query: inputValue,
      }).then(r => r.data.results);
    }, placeholderData: [], refetchOnReconnect: false, refetchOnWindowFocus: false, staleTime: 5000,
  });

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const handleNoOptionsMessage = useCallback((obj: { inputValue: string }) => {
    if (optionsQuery.isFetching) return `Loading...`;
    return obj.inputValue?.length ? `No tags found` : null;
  }, [optionsQuery.isFetching]);

  return (
    <Presentation.Row>
      <Presentation.Label>Tag</Presentation.Label>
      <Presentation.Value>
        <Select<TagFilter.Item, true>
          styles={selectStyles}
          components={{
            DropdownIndicator: null,
          }}
          theme={selectTheme}
          value={selected}
          isMulti
          isSearchable
          isClearable
          isLoading={optionsQuery.isFetching}
          options={optionsQuery.data}
          placeholder={`Search tags...`}
          noOptionsMessage={handleNoOptionsMessage}
          getOptionLabel={o => o.name}
          getOptionValue={o => `${o.id}`}
          onChange={handleChange}
          onInputChange={handleInputChange}
          menuPlacement="auto" />
      </Presentation.Value>
    </Presentation.Row>
  );
}