import { useCallback, useState } from 'react';
import type { WorkspaceSearchFull } from '@/types';
import {
  SearchLoadingAllContext, SearchLoadingDispatchContext, SearchLoadingFileContext,
  SearchLoadingFolderContext, SearchLoadingResetContext,
} from './Context';
import type { SearchLoadingContextState } from './interfaces';

export default function Loading({ children }: ChildrenProps) {
  const [state, dispatch] = useState<SearchLoadingContextState>(getInitialState());

  const reset = useCallback((_: WorkspaceSearchFull.SearchTab) => {
    // dispatch(prev => ({
    //   ...prev,
    //   [tab]: { untouched: true, loading: true },
    // }));
    dispatch(getInitialState());
  }, []);

  return (
    <SearchLoadingDispatchContext.Provider value={dispatch}>
      <SearchLoadingResetContext.Provider value={reset}>
        <SearchLoadingAllContext.Provider value={state.all}>
          <SearchLoadingFileContext.Provider value={state.files}>
            <SearchLoadingFolderContext.Provider value={state.folders}>
              {children}
            </SearchLoadingFolderContext.Provider>
          </SearchLoadingFileContext.Provider>
        </SearchLoadingAllContext.Provider>
      </SearchLoadingResetContext.Provider>
    </SearchLoadingDispatchContext.Provider>
  );
}

function getInitialState(): SearchLoadingContextState {
  return {
    all: { untouched: true, loading: true },
    files: { untouched: true, loading: true },
    folders: { untouched: true, loading: true },
  };
}

export { Loading };
