import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/api';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';

type Props = {
  chatInstanceId: number;
  onSuccess?: () => void;
};

export const useToggleChatBookmark = ({ chatInstanceId, onSuccess }: Props) => {

  const dispatch = useContext(ChatStateDispatchContext);

  const mutation = useMutation(['chat:patch:bookmark'], (bookmarked: boolean) => {
    return $api.toggleChatInstanceBookmark({
      chatInstanceId,
      bookmarked,
    });
  }, {
    onSuccess: res => {
      dispatch({
        type: 'chat-instance-bookmarked',
        payload: {
          chatInstance: {
            id: chatInstanceId,
            bookmarked: res.bookmarked,
          },
        },
      });
      onSuccess?.();
    },
  });

  return mutation;
};