import { useCallback } from 'react';
import type { TableInstance } from 'react-table';
import { cx } from '@/utils';
import { TablePagination } from '@/components/presentation/TablePagination';
/* import { RowsPerPage } from '@/components/presentation/TablePagination'; */
import styles from './style/Footer.module.css';

type Props = {
  classes?: FooterClasses;
  instance: (TableInstance & {
    canNextPage: boolean;
    canPreviousPage: boolean;
    nextPage: () => void;
    previousPage: () => void;
    state: {
      pageIndex: number;
      pageSize: number;
    };
  });
  pageSizes?: number[];
};

type FooterClasses = {
  root?: string;
  pagination?: string;
  pageSize?: string;
};

export const GroupTableFooter = ({ classes, instance, pageSizes }: Props) => {
  /*
  const updatePageSize = useCallback((pageSize: number) => {
    instance.gotoPage(0);
    instance.setPageSize(pageSize);
  }, [
    instance.gotoPage,
    instance.setPageSize,
  ]);
  */
  return (
    <div className={cx(styles.root, classes?.root)}>
      {/* <RowsPerPage
        className={cx(styles.pageSize, classes?.pageSize)}
        value={instance.state.pageSize}
        onSelect={updatePageSize}
        options={pageSizes || [25, 50, 100]} /> */}
      <TablePagination
        canPreviousPage={instance.canPreviousPage}
        canNextPage={instance.canNextPage}
        pageIndex={instance.state.pageIndex}
        nextPage={instance.nextPage}
        pageSize={instance.state.pageSize}
        previousPage={instance.previousPage}
        totalCount={instance.rows.length} />
    </div>
  );
};

export default GroupTableFooter;