import { memo } from 'react';
import styled from '@emotion/styled';
import { pulse } from '@/utils/color';
import { Lightbulb } from './Lightbulb';

type Props = {
  onClick?: () => void;
};

export const ChatWidget = memo(({ onClick }: Props) => {

  return (
    <AppWidthWrapper>
      <Root onClick={onClick}>
        <Lightbulb size={28} />
      </Root>
    </AppWidthWrapper>
  );
});

const WidgetSize = 75;
const positioning = 35;

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',

  bottom: 65,
  right: 0,
  '@media (min-width: 1465px)': {
    bottom: positioning,
    right: -WidgetSize,
  },

  width: WidgetSize,
  height: WidgetSize,
  borderRadius: '50%',

  background: theme.palette.primary.light6,
  boxShadow: `0 0 0 0 ${theme.palette.primary.light6}`,
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  animation: `${pulse(theme.palette.primary.light6)} 6s infinite`,
}));

const AppWidthWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  maxWidth: theme.breakpoints.values.navbarWidth,
  margin: '0 auto',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndex.widget,
  boxSizing: 'border-box',
}));
