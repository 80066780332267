/** @jsxImportSource @emotion/react */
import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';
import styled from '@emotion/styled';

type TableItem = {
  name: string;
  value: ReactNode;
};

type Props = {
  items: TableItem[];
};

export const CitationsTable = memo(({ items }: Props) => {

  const rows = useMemo(() => {
    return items.map((item, i) => (
      <ItemContainer key={i}>
        <AttributeContainer>
          <LabelAttribute>{item.name}</LabelAttribute>
        </AttributeContainer>
        <AttributeContainer>
          <Attribute>{item.value}</Attribute>
        </AttributeContainer>
      </ItemContainer>
    ));
  }, [items]);

  return (
    <Container css={{ margin: '10px 0 0 0', width: '100%' }}>
      {rows}
    </Container>
  );

});


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ItemContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '125px auto',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AttributeContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(var(--column-width-min), 1fr))',
}));

export const Container = styled.ol(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.gray.light1}`,
  borderRadius: 4,
  margin: 0,
  padding: 0,
}));

export const Attribute = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  borderRight: `1px solid ${theme.palette.gray.light2}`,
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  padding: 8,
  fontSize: 14,
  overflow: 'hidden',
  height: '100%',
}));

export const LabelAttribute = styled(Attribute)(({ theme }) => ({
  backgroundColor: theme.palette.gray.light3,
  fontFamily: theme.fonts.semiBold,
  fontSize: 14,
}));