import { parseISO, parseJSON } from 'date-fns';

// https://github.com/date-fns/date-fns/pull/1463#issuecomment-551228882
export function safeJsonDate(value: string | number): Date {
  return value ? parseJSON(value) : null;
}

export function safeIsoDate(value: string): Date {
  return value ? parseISO(value) : null;
}

//Use two different regexes since postgres can spit out the date in two different ways depending on whether you're building JSON with it or not
//For example, if not JSON-ified "2022-04-01 13:52:11.260 -0400" but if you put it in JSON you get "2022-04-01T13:52:11.260802-04:00"
//TODO: consolidate these regexes or find a better validation method
const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
const postgresJSONBFormat = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

function isIsoDateString(value: unknown): boolean {
  return value && typeof value === 'string' && (isoDateFormat.test(value) || postgresJSONBFormat.test(value));
}

export function transformDates(body: string | unknown): unknown {
  let parsedBody = body;
  if (typeof body === 'string') {
    try {
      parsedBody = JSON.parse(body);
    } catch (e) {
      return body;
    }
  }

  return parseDates(parsedBody);
}

function parseDates(body: string | unknown) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body;

  for (const key of Object.keys(body)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value = body[key];

    if (isIsoDateString(value)) {
      body[key] = parseISO(value);
    }
    else if (typeof value === 'object') {
      body[key] = parseDates(value);
    }
  }

  return body;
}