import { useCallback, useEffect, useState } from 'react';
import type { Point } from '@/types/geometry';

export type DragEvent = (point: Point) => void;

type Props = {
  onDragEnd?: () => void;
  onDragStart?: DragEvent;
  onDrag?: DragEvent;
};

function getTouchPoint(e: TouchEvent): Point {
  if (e.changedTouches.length !== 1) {
    return null;
  }

  const touch = e.changedTouches[0];
  return {
    x: touch.clientX,
    y: touch.clientY,
  };
}

function getMousePoint(e: MouseEvent): Point {
  return {
    x: e.x,
    y: e.y,
  };
}

const useDrag = ({
  onDrag,
  onDragEnd,
  onDragStart,
}: Props) => {
  const [isDragging, setDragging] = useState<boolean>(false);

  const handleTouchMove = useCallback((e: TouchEvent) => {
    if (onDrag) {
      onDrag(getTouchPoint(e));
    }

    if (e.cancelable) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, [onDrag]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (onDrag) {
      onDrag(getMousePoint(e));
    }

    if (e.cancelable) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, [onDrag]);

  const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setDragging(true);

    if (onDragStart) {
      onDragStart(getTouchPoint(e.nativeEvent));
    }
  }, [onDragStart]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDragging(true);

    if (onDragStart) {
      onDragStart(getMousePoint(e.nativeEvent));
    }
  }, [onDragStart]);

  const handleMouseUp = useCallback(() => {
    setDragging(false);

    if (onDragEnd) {
      onDragEnd();
    }
  }, [onDragEnd]);

  const handleTouchEnd = useCallback(() => {
    setDragging(false);

    if (onDragEnd) {
      onDragEnd();
    }
  }, [onDragEnd]);

  useEffect(() => {
    if (isDragging) {
      window.document.body.addEventListener('mousemove', handleMouseMove, { passive: false });
      window.document.body.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.document.body.addEventListener('mouseup', handleMouseUp, { passive: false });
      window.document.body.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      window.document.body.removeEventListener('mousemove', handleMouseMove, false);
      window.document.body.removeEventListener('touchmove', handleTouchMove, false);
      window.document.body.removeEventListener('mouseup', handleMouseUp, false);
      window.document.body.removeEventListener('touchend', handleTouchEnd, false);
    };
  }, [
    isDragging,
    handleMouseMove,
    handleTouchMove,
    handleMouseUp,
    handleTouchEnd,
  ]);

  return [
    isDragging,
    handleMouseDown,
    handleTouchStart,
  ] as const;
};

export { useDrag };
export default useDrag;