import { forwardRef } from 'react';
import { cx } from '@/utils';
import styles from './styles/SoundInput.module.css';

type Props = {
  color: string;
  paused?: boolean;
};

export const SoundInput = forwardRef<HTMLDivElement, Props>(({ color, paused }: Props, ref) => {
  return (
    <div className={cx(styles.bars, paused ? styles.paused : null)} ref={ref}>
      <div className={styles.bar} style={{ background: color }}></div>
      <div className={styles.bar} style={{ background: color }}></div>
      <div className={styles.bar} style={{ background: color }}></div>
      <div className={styles.bar} style={{ background: color }}></div>
      <div className={styles.bar} style={{ background: color }}></div>
    </div>
  );
});