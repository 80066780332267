import { useCallback, useContext, useEffect, useRef } from 'react';
import * as $api from '@/api';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { ChatQueryStatus } from '@/enums';
import { ChatSocketEvent } from '@/enums/websocket';
import type { Chat } from '@/types';
import { getQuerySiblings } from '@/utils';
import { useChatQuerySocket } from '@/websocket';
import type { QueryAvailable, QueryErrored, QueryTakingTooLong } from '@/websocket/interfaces';
import { ChatSessionQueriesContext } from './context';
import { useScrollToBottomOfMessages } from './hooks';

type Props = {
  queryIdentifier: string;
} & ChildrenProps;

type QueryAvailable = {
  chatInstance: Chat.Instance;
  query: Chat.Query;
  regenerated: boolean;
};

type QueryErrored = {
  queryIdentifier: string;
  traceId: string;
};

const useGetQuerySiblings = () => {
  const queries = useContext(ChatSessionQueriesContext);

  return useCallback((queryIdentifier: string) => {
    return getQuerySiblings({
      queryIdentifier,
      queries,
    });
  }, [queries]);
};

export const QueryEventsContainer = ({ queryIdentifier, children }: Props) => {

  const scrollToBottom = useScrollToBottomOfMessages();
  const dispatch = useContext(ChatStateDispatchContext);
  const pollingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const getQuerySiblings = useGetQuerySiblings();

  const handleQueryErrored = useCallback((data: QueryErrored) => {
    dispatch({
      type: 'query-errored',
      payload: {
        queryIdentifier: data.queryIdentifier,
        traceId: data.traceId,
      },
    });
    scrollToBottom();
  }, [dispatch, scrollToBottom]);

  const handleQueryAvailable = useCallback((data: QueryAvailable) => {
    if (data.regenerated) {
      dispatch({
        type: 'query-variant-response-available',
        payload: {
          chatInstance: data.chatInstance,
          query: data.query,
        },
      });
    } else {
      dispatch({
        type: 'query-response-available',
        payload: {
          chatInstance: data.chatInstance,
          query: data.query,
        },
      });
    }
    scrollToBottom();
  }, [dispatch, scrollToBottom]);

  const getPendingQuery = useCallback(() => {
    return $api.getPendingQuery({
      queryIdentifier,
    }).then(res => {
      if (!res.query) return;

      let keepPolling = false;
      if (res.query.status === ChatQueryStatus.Error) {
        console.log('Polling: Query Errored');
        handleQueryErrored({
          queryIdentifier: res.query.identifier,
          traceId: null,
        });
      } else if (res.query.status === ChatQueryStatus.Complete || res.query.status === ChatQueryStatus.FollowupsAvailable) {
        console.log('Polling: Query Available');
        if (!res.chatInstance.name) {
          keepPolling = true;
          console.log('Polling: Chat Instance Name missing');
        } else {

          const siblings = getQuerySiblings(res.query.mapping.parentIdentifier);
          const regenerated = siblings.length > 1;

          console.log('Regenerated:', regenerated);

          handleQueryAvailable({
            chatInstance: res.chatInstance,
            query: res.query,
            regenerated,
          });
        }
      } else {
        console.log('Polling: Query Pending');
        keepPolling = true;
      }

      if (keepPolling) {
        const pollingInterval = 2000;
        pollingTimeoutRef.current = setTimeout(() => {
          console.log('Polling for query', queryIdentifier);
          return getPendingQuery();
        }, pollingInterval);
      }
    });
  }, [getQuerySiblings, handleQueryAvailable, handleQueryErrored, queryIdentifier]);

  useEffect(() => {
    if (pollingTimeoutRef.current) clearTimeout(pollingTimeoutRef.current);

    pollingTimeoutRef.current = setTimeout(() => {
      console.log('Start polling for query', queryIdentifier);
      return getPendingQuery();
    }, 10000);

    return () => {
      if (pollingTimeoutRef.current) clearTimeout(pollingTimeoutRef.current);
    };
  }, [getPendingQuery, queryIdentifier]);

  const handleQueryAvailableEvent = useCallback((data: QueryAvailable.Data) => {
    if (data.queryIdentifier !== queryIdentifier) return;

    if (pollingTimeoutRef.current) clearTimeout(pollingTimeoutRef.current);

    return $api.getQuery({
      chatInstanceId: data.chatId,
      queryIdentifier: data.queryIdentifier,
    }).then(res => {
      handleQueryAvailable({
        chatInstance: res.chatInstance,
        query: res.query,
        regenerated: data.regenerated,
      });
    });
  }, [handleQueryAvailable, queryIdentifier]);

  const handleTakingTooLongEvent = useCallback((data: QueryTakingTooLong.Data) => {
    if (data.identifier === queryIdentifier) {
      dispatch({
        type: 'query-taking-long',
        payload: {
          queryIdentifier: data.identifier,
        },
      });
    }
  }, [dispatch, queryIdentifier]);

  const handleQueryErroredEvent = useCallback((data: QueryErrored.Data) => {
    if (data.queryIdentifier !== queryIdentifier) return;

    handleQueryErrored(data);
  }, [handleQueryErrored, queryIdentifier]);

  const socket = useChatQuerySocket(queryIdentifier);

  useEffect(() => {
    if (!socket) return;

    socket.on(ChatSocketEvent.QueryAvailable, handleQueryAvailableEvent);
    socket.on(ChatSocketEvent.QueryErrored, handleQueryErroredEvent);
    socket.on(ChatSocketEvent.QueryTakingTooLong, handleTakingTooLongEvent);

    return () => {
      socket.off(ChatSocketEvent.QueryAvailable, handleQueryAvailableEvent);
      socket.off(ChatSocketEvent.QueryErrored, handleQueryErroredEvent);
      socket.off(ChatSocketEvent.QueryTakingTooLong, handleTakingTooLongEvent);
    };
  }, [handleTakingTooLongEvent, handleQueryAvailableEvent, handleQueryErroredEvent, socket]);

  return (
    <>
      {children}
    </>
  );
};