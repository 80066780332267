import { useCallback } from 'react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import { X } from 'react-feather';
import type { BaseModalProps } from '@/hooks/useModal';
import { BasicMarkdown } from '../presentation/Markdown/Markdown';
import { useChatGuidelinesCookie } from './hooks';

type Props = {
  guidelines: string;
} & BaseModalProps;

export const ChatGuidelinesModal = ({ guidelines, open, onClose }: Props) => {
  const { dismissGuidelines } = useChatGuidelinesCookie();

  const onModalClose = useCallback(() => {
    dismissGuidelines();
    onClose();
  }, [dismissGuidelines, onClose]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onModalClose}
      open={open}>

      <Header>
        <Title>Usage Guidelines</Title>
        <StyledX onClick={onModalClose} />
      </Header>

      <Content>

        <BasicMarkdown>
          {guidelines}
        </BasicMarkdown>
      </Content>
    </Dialog>
  );
};

const StyledX = styled(X)({
  cursor: 'pointer',
  position: 'absolute',
  top: 15,
  right: 10,
  height: 24,
  width: 24,
});

const Header = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `15px 10px`,
  borderBottom: `1px solid ${theme.palette.gray.dark1}`,
  position: 'relative',
}));

const Title = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  fontSize: 20,
  color: theme.palette.black.main,
}));

const Content = styled.div({
  padding: 30,
});