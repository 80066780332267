import { usePopupState } from 'material-ui-popup-state/hooks';
import Containers from './Containers';
import { WorkspaceGlobalSearchBarPopupContext } from './Context';
import Popper from './Popper';
import styles from './style.module.css';

export default function WorkspaceGlobalSearchBar() {
  // const { fetchPast } = usePastSearches();

  const popupState = usePopupState({
    variant: 'popper',
    popupId: styles.portal,
    disableAutoFocus: true,
  });

  const value = {
    isOpen: popupState.isOpen,
    setOpen: popupState.setOpen,
  };
  /*
  useEffect(() => {
    fetchPast();
  }, []);
  */
  return (
    <div className={styles.search}>
      <div className={styles.searchbar}>
        <WorkspaceGlobalSearchBarPopupContext.Provider value={value}>
          <Containers>
            <Popper popupState={popupState} />
          </Containers>
        </WorkspaceGlobalSearchBarPopupContext.Provider>
      </div>
    </div>
  );
}

export { WorkspaceGlobalSearchBar };