import { useContext } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { cx } from '@/utils';
import { ChipContext } from './Chip.Context';
import styles from './style/ChipBody.module.css';

function Basic(props: ChipBodyBasicProps) {
  const { disabled } = useContext(ChipContext);

  const classes = cx(styles.bodyBase, {
    [styles.disabled]: props.disabled ?? disabled,
  });

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
}

function Action({
  variant = 'add',
  ...props
}: ChipBodyActionProps) {
  const { disabled, clickable } = useContext(ChipContext);

  const classes = cx(styles.bodyAction, {
    [styles.clickable]: clickable,
    [styles.disabled]: props.disabled ?? disabled,
  }, props.className);

  const Icon = ActionIcons[variant];

  return (
    <div className={classes}>
      {props.children}
      <div className={styles.action} onClick={!disabled ? props.onClick : undefined}>
        <Icon />
      </div>
    </div>
  );
}

const ActionIcons = {
  add: AddCircleIcon,
  remove: CancelIcon,
};

export const ChipBody = {
  Basic,
  Action,
};

export type ChipBodyBasicProps = {
  children?: React.ReactNode;
  disabled?: boolean;
};

export type ChipBodyActionProps = {
  className?: string;
  variant?: ChipBodyActionVariant;
  onClick?: () => unknown;
} & ChipBodyBasicProps;

type ChipBodyActionVariant =
  'add'
  | 'remove';