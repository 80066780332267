import { useContext } from 'react';
import { SearchLoadingAllContext, SearchResultsAllContext } from '@/containers/WorkspaceGlobalSearch/Context';
import type { WorkspaceSearchFull } from '@/types';
import { formatPlurality } from '@/utils';
// import MemberSearchBanner from './MemberSearchBanner';
// import MembersTable from './Search.Table.Members';
import FilesTable from './Search.Table.Files';
import FoldersTable from './Search.Table.Folders';
import styles from './style/Tab.module.css';

const pageSize = 10;

export default function SearchTabAll() {
  const loading = useContext(SearchLoadingAllContext);
  const { files, folders } = useContext(SearchResultsAllContext);

  const isLoading = loading.untouched && loading.loading;

  return (
    <div className={styles.root}>
      <Files
        result={files}
        isLoading={isLoading} />
      <Folders
        result={folders}
        isLoading={isLoading} />
    </div>
  );
}

function Files({ result, isLoading }: FilesProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'File')})`
    : null;

  return (
    <FilesTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Folders({ result, isLoading }: FoldersProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Folder')})`
    : null;

  return (
    <FoldersTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function getCountLabel(pageItems: number, total: number, label: string) {
  return total > pageItems
    ? `+${pageSize} ${formatPlurality(label, pageSize)}`
    : `${pageItems} ${formatPlurality(label, pageItems)}`;
}

export { SearchTabAll };

type FilesProps = {
  result: WorkspaceSearchFull.File.Results;
  isLoading: boolean;
};

type FoldersProps = {
  result: WorkspaceSearchFull.Folder.Results;
  isLoading: boolean;
};