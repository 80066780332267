import { createNamedContext } from '@/utils';
import type {
  PastSearchesContextValue, SearchFiltersQueryContextValue, SearchLoadingContextState,
  SearchLoadingDispatch, SearchLoadingReset, SearchResultsContextState, SearchResultsDispatch,
  SearchResultsReset,
} from './interfaces';

export const SearchFiltersContext = createNamedContext<SearchFiltersQueryContextValue>(undefined, 'WorkspaceGlobalSearch.SearchFilters.Context');

export const PastSearchesContext = createNamedContext<PastSearchesContextValue>(undefined, 'WorkspaceGlobalSearch.PastSearches.Context');

export const SearchResultsDispatchContext = createNamedContext<SearchResultsDispatch>(undefined, 'WorkspaceGlobalSearch.Results.Dispatch');
export const SearchResultsResetContext = createNamedContext<SearchResultsReset>(undefined, 'WorkspaceGlobalSearch.Results.Reset');

export const SearchResultsAllContext = createNamedContext<SearchResultsContextState['all']>(undefined, 'WorkspaceGlobalSearch.Results.All');
export const SearchResultsFileContext = createNamedContext<SearchResultsContextState['files']>(undefined, 'WorkspaceGlobalSearch.Results.File');
export const SearchResultsFolderContext = createNamedContext<SearchResultsContextState['folders']>(undefined, 'WorkspaceGlobalSearch.Results.Folder');

export const SearchLoadingDispatchContext = createNamedContext<SearchLoadingDispatch>(undefined, 'WorkspaceGlobalSearch.Loading.Dispatch');
export const SearchLoadingResetContext = createNamedContext<SearchLoadingReset>(undefined, 'WorkspaceGlobalSearch.Loading.Reset');

export const SearchLoadingAllContext = createNamedContext<SearchLoadingContextState['all']>(undefined, 'WorkspaceGlobalSearch.Loading.All');
export const SearchLoadingFileContext = createNamedContext<SearchLoadingContextState['files']>(undefined, 'WorkspaceGlobalSearch.Loading.File');
export const SearchLoadingFolderContext = createNamedContext<SearchLoadingContextState['folders']>(undefined, 'WorkspaceGlobalSearch.Loading.Folder');