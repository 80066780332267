import { useCallback, useContext } from 'react';
import { ButtonOutlined } from '@/components/presentation/ButtonOutlined';
import { WorkspaceFileSummaryContext } from '@/containers/Workspace.File.Preview/Context';

export function SummaryActionDownload() {
  const ctx = useContext(WorkspaceFileSummaryContext);

  const handleDownload = useCallback(() => {
    ctx.download();
  }, [ctx]);

  return (
    <ButtonOutlined
      color="silver"
      fontWeight="bold"
      onClick={handleDownload}>
      Export
    </ButtonOutlined>
  );
}