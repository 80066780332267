import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as $api from '@/api';
import { getAuthProvider } from '@/auth/auth-provider';
import { useAppDispatch } from '@/store';
import { actions } from '@/store/actions';

export const useFetchApp = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const fetchApp = () => {
    dispatch(actions.appStateLoadStarted());

    $api.getAppState()
      .then(data => {
        if (data.session && getAuthProvider().authSource === 'cookie') {
          getAuthProvider().authenticate({
            authSource: 'cookie',
            sessionMeta: {
              authenticated: data.session.authenticated,
              expiry: data.session.expiry,
            },
          });
        }
        // hack for now until refactor
        queryClient.refetchQueries(['get:app/theming'])
          .then(() => {
            setTimeout(() => {
              dispatch(actions.appStatedLoaded({
                group: data.group,
                user: {
                  id: data.user.id,
                  username: data.user.username || data.user.email,
                  email: data.user.email,
                  profile: {
                    firstName: data.user.firstName,
                    lastName: data.user.lastName,
                  },
                  permissions: data.user.permissions,
                  roles: data.user.roles,
                  isFirstSession: data.user.isFirstSession,
                },
                workspace: data.workspace,
              }));
            }, 0);
          });
      })
      .catch((e: Error) => {
        dispatch(actions.appStateLoadError({ error: e }));
      });
  };

  return useCallback(fetchApp, [
    queryClient,
    dispatch,
  ]);
};