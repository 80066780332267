import { useContext, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ExternalLink } from 'react-feather';
import { BrandInsightsModalContext } from '@/components/BrandInsights/context';
import { ActiveChatSessionContext } from '@/components/Chat/context';
import { useIsFullPageChat } from '@/components/Chat/hooks';
import { ChatContextType } from '@/enums';
import { getLocationFor } from '@/utils';

export const JumpToPage = () => {

  const to = useJumpToLocation();
  const isFullpageChat = useIsFullPageChat();
  const [modalIsOpen, toggleModal] = useContext(BrandInsightsModalContext);

  const onClick = useCallback(() => {
    if (!isFullpageChat && modalIsOpen) {
      toggleModal();
    }
  }, [isFullpageChat, modalIsOpen, toggleModal]);

  if (!to) {
    return null;
  }

  return (
    <Link to={to} onClick={onClick}>
      <TextContainer>
        {isFullpageChat && <Text>Jump to Page </Text>}
        <StyledExternalLink />
      </TextContainer>
    </Link>
  );
};

const useJumpToLocation = () => {
  const chat = useContext(ActiveChatSessionContext);
  const isFullpageChat = useIsFullPageChat();

  return useMemo(() => {
    if (!isFullpageChat) {
      if (chat?.identifier && chat?.id) {
        return `/chat/${chat.identifier}`;
      } else {
        return null;
      }
    }

    switch (chat.context.type) {
      case ChatContextType.KolProfile: {
        return null;
      }

      case ChatContextType.Document: {
        return getLocationFor.workspaces.file({
          fileId: chat.context.data.documentId,
        });
      }

      // case ChatContextType.Project: {
      //   return getLocationFor.project.root({
      //     slug: String(chat.context.data.projectId),
      //   });
      // }

      // case ChatContextType.Brand: {
      //   return getLocationFor.brand.root({
      //     brandId: chat.context.data.brandId,
      //   });
      // }

      case ChatContextType.Transcript: {
        return null;
        //   return chat.context.data.callId && chat.context.data.projectId
        //     ? getLocationFor.call.transcript({
        //       callId: chat.context.data.callId,
        //       slug: chat.context.data.projectId.toString(),
        //     })
        //     : getLocationFor.workspaces.transcript({
        //       transcriptId: chat.context.data.transcriptId,
        //     });
      }

      case ChatContextType.Folder: {
        return chat.context.data.folderId
          ? getLocationFor.workspaces.folder({
            folderId: chat.context.data.folderId,
          })
          : null;
      }

      case ChatContextType.Global:
        return null;

      default:
        throw new UnreachableCaseError(chat.context);

    }
  }, [chat?.context, chat?.id, chat?.identifier, isFullpageChat]);
};

const Text = styled.div`
  color: ${({ theme }) => theme.palette.sentiment.primary.main};
  margin-right: 5px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.palette.sentiment.primary.main};
`;