import type { WorkspaceSearchFull } from '@/types';
import { safeJsonDate } from './date';

export function normalizeSearchFullFileResults(data: WorkspaceSearchFull.File.Results<string>): WorkspaceSearchFull.File.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullFileResult),
  };
}

function normalizeSearchFullFileResult(data: WorkspaceSearchFull.File.Result<string>): WorkspaceSearchFull.File.Result {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullFolderResults(data: WorkspaceSearchFull.Folder.Results<string>): WorkspaceSearchFull.Folder.Results {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullFolderResult),
  };
}

function normalizeSearchFullFolderResult(data: WorkspaceSearchFull.Folder.Result<string>): WorkspaceSearchFull.Folder.Result {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}