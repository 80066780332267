import { createId } from '@paralleldrive/cuid2';
import { ChatContextType } from '@/enums/chat';
import { ChatDataSource, ChatSource } from '@/enums/chat.filters';
import { KolSearchStatus } from '@/enums/kol';
import type { BrandInsightsPageContext, Chat } from '@/types';
import type { ChatSessionState, ChatState, SettingsState } from './interfaces';
import type { ChatSessionManagerState } from './interfaces.chat-session-manager';

type GenerateEmptyChatSessionStateData = {
  context?: BrandInsightsPageContext.Item;
};

export function generateIntialKolProfileContext(): BrandInsightsPageContext.KolProfile {
  return { type: ChatContextType.KolProfile, data: { id: null }, metadata: { name: '' } };
}

export function buildDefaultChatContext(contextType: ChatContextType): BrandInsightsPageContext.Item {
  switch (contextType) {
    case ChatContextType.KolProfile:
      return generateIntialKolProfileContext();

    case ChatContextType.Global:
      return { type: ChatContextType.Global };

    default:
      return null;
  }
}

export function generateEmptyChatSessionState({ context }: GenerateEmptyChatSessionStateData): ChatSessionState.State {

  return {
    chatInstance: {
      createdOn: new Date(),
      id: null,
      identifier: createId(),
      name: '',
      currentQueryIdentifier: null,
      context,
      shared: null,
      bookmarked: false,
    },
    input: '',
    selectedQueryIdentifier: null,
    queries: {},
    filters: generateEmptyChatSessionFiltersState({ context }),
    fileUploads: [],
    pendingFindQuotes: false,
    kolSearches: [],
  };
}

export function generateEmptyKolSearch(): Chat.KolSearch {
  return {
    id: null,
    createdOn: new Date(),
    status: KolSearchStatus.ProfileRecommendations,
    identifier: createId(),
    followupQuestions: [],
    form: {
      name: '',
    },
    results: null,
    profile: null,
    recommendations: {
      status: 'uninitialized',
      items: [],
    },
    citations: [],
  };
}

type GenerateEmptyChatSessionFiltersState = {
  context?: BrandInsightsPageContext.Item;
};

export function generateEmptyChatSessionFiltersState({ context }: GenerateEmptyChatSessionFiltersState): ChatSessionState.State['filters'] {
  switch (context.type) {
    case ChatContextType.KolProfile:
      return {
        custom: [],
        dataSources: [{
          id: ChatDataSource.VeevaLink,
          name: 'Veeva Link',
        }],
        sources: [{
          id: ChatSource.Profiles,
          name: 'Profiles',
        }, {
          id: ChatSource.MslInteractions,
          name: 'MSL Interactions',
        }],
        minDocumentDate: null,
        tags: [],
      };

    case ChatContextType.Global:
    case ChatContextType.Document:
    case ChatContextType.Folder:
    default:
      return {
        custom: [],
        dataSources: [],
        sources: [{
          id: ChatSource.Presentations,
          name: 'Presentations',
        }, {
          id: ChatSource.Text,
          name: 'Text',
        }],
        minDocumentDate: null,
        tags: [],
      };
  }
}

export function generateEmptyChatSessionManagerState(): ChatSessionManagerState.State {
  return {
    currentChatInstanceIdentifier: null,
    nextChatInstanceIdentifier: null,
    status: 'uninitialized',
  };
}

type GenerateEmptyChatStateData = {
  settings: SettingsState.State;
} & GenerateEmptyChatSessionStateData;

export function generateEmptyChatState(data: GenerateEmptyChatStateData): ChatState.State {
  const context = data.context || buildDefaultChatContext(data.settings.defaultContextType);

  return {
    settings: data.settings,
    history: {
      manager: {
        mode: 'page',
        cursor: null,
        loading: false,
        hasMore: false,
        initialized: false,
      },
      items: [],
      shared: [],
    },
    session: generateEmptyChatSessionState({ context }),
    sessionManager: generateEmptyChatSessionManagerState(),
  };
}