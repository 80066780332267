/* eslint-disable react-refresh/only-export-components */
import { useContext, useMemo } from 'react';
import { createBrowserRouter, Navigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { AppSubscriber } from '@/containers/Subscriber/AppSubscriber';
import { RootVendors } from '@/containers/Subscriber/RootVendors';
import { Layout } from '@/Layout';
import { Branding } from '@/screens/Branding';
import { ChatScreen } from '@/screens/Chat';
import { OAuth2, OAuth2Logout } from '@/screens/Inbound';
import { OAuth2Login } from '@/screens/Inbound/Auth.OAuth2.Login';
import { AppInitializing } from '@/screens/Initializing';
import { LoginScreen } from '@/screens/Login';
import { LogoutScreen } from '@/screens/Logout';
import { WorkspaceFile, FileNotFound } from '@/screens/Workspace.File';
import { WorkspaceFilesTable } from '@/screens/Workspace.FilesTable';
import { WorkspaceFolder } from '@/screens/Workspace.Folder';
import { Search as WorkspaceSearch } from '@/screens/WorkspaceGlobalSearch';
import { useAppSelector } from '@/store';
import { ProvideDefaultPathsContext, type ProvideDefaultPathsValue } from './context';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const redirectLoginPath = '/login';
const manualLoginPath = '/_login';
const defaultHomePath = '/';

export function createRouter() {
  return sentryCreateBrowserRouter([
    {
      path: '/',
      element: (
        <RootVendors>
          <AppSubscriber>
            <ProvideDefaultPaths>
              <Layout />
            </ProvideDefaultPaths>
          </AppSubscriber>
        </RootVendors>
      ),
      errorElement: null, // note: trigger fallback component
      children: [
        { index: true, element: <Navigate to="/chat" /> },
        {
          path: '_/auth/oauth2/*',
          children: [
            { path: 'logout', Component: OAuth2Logout },
            { path: ':identifier', Component: OAuth2 },
          ],
        },
        { path: redirectLoginPath.substring(1), Component: OAuth2Login },
        {
          path: 'branding',
          element: (
            <AuthenticatedGuard>
              <AppInitializedGuard>
                <Branding />
              </AppInitializedGuard>
            </AuthenticatedGuard>
          ),
        },
        {
          path: 'chat/*',
          children: [
            {
              path: ':chatIdentifier?',
              element: (
                <AuthenticatedGuard>
                  <AppInitializedGuard>
                    <ChatScreen />
                  </AppInitializedGuard>
                </AuthenticatedGuard>
              ),
            },
          ],
        },
        {
          path: manualLoginPath.substring(1),
          element: (
            <BrandingInitializedGuard>
              <UnauthenticatedGuard>
                <LoginScreen />
              </UnauthenticatedGuard>
            </BrandingInitializedGuard>
          ),
        },
        {
          path: 'logout',
          element: <LogoutScreen />,
        },
        {
          path: 'workspaces',
          element: (
            <AuthenticatedGuard>
              <AppInitializedGuard>
                <WorkspaceFilesTable />
              </AppInitializedGuard>
            </AuthenticatedGuard>
          ),
        },
        {
          path: 'workspaces/search',
          element: (
            <AuthenticatedGuard>
              <AppInitializedGuard>
                <WorkspaceSearch />
              </AppInitializedGuard>
            </AuthenticatedGuard>
          ),
        },
        {
          path: 'workspaces/folders/:folderId/*',
          element: (
            <AuthenticatedGuard>
              <AppInitializedGuard>
                <WorkspaceFolder />
              </AppInitializedGuard>
            </AuthenticatedGuard>
          ),
        },
        {
          path: 'workspaces/files/not-found',
          element: (
            <AuthenticatedGuard>
              <AppInitializedGuard>
                <FileNotFound />
              </AppInitializedGuard>
            </AuthenticatedGuard>
          ),
        },
        {
          path: 'workspaces/files/:fileId/*',
          element: (
            <AuthenticatedGuard>
              <AppInitializedGuard>
                <WorkspaceFile />
              </AppInitializedGuard>
            </AuthenticatedGuard>
          ),
        },
        // note: last catch all route
        { path: '*', element: <Navigate to={defaultHomePath} replace /> },
      ],
    },
  ]);
}

function ProvideDefaultPaths(props: ChildrenProps) {
  const value = useMemo<ProvideDefaultPathsValue>(() => ({
    loginPath: redirectLoginPath,
    homePath: defaultHomePath,
  }), []);

  return (
    <ProvideDefaultPathsContext.Provider value={value}>
      {props.children}
    </ProvideDefaultPathsContext.Provider>
  );
}

function AppInitializedGuard(props: ChildrenProps) {
  const appState = useAppSelector(state => state.appState);
  const appInitialized = useMemo(() => appState.initialized, [appState.initialized]);

  if (!appInitialized) return <AppInitializing />;

  return props.children;
}

function BrandingInitializedGuard(props: ChildrenProps) {
  const { query } = useContext(AppThemingContext);
  const brandingInitialized = useMemo(() => !query.isPlaceholderData && !query.isFetching, [query.isPlaceholderData, query.isFetching]);

  if (!brandingInitialized) return <AppInitializing />;

  return props.children;
}

type AuthenticatedGuardProps = {
  children: React.ReactNode;
  unauthenticatedRedirectPath?: string;
};

function AuthenticatedGuard(props: AuthenticatedGuardProps) {
  const location = useLocation();
  const appState = useAppSelector(state => state.appState);
  const defaultPaths = useContext(ProvideDefaultPathsContext);

  if (!appState.authenticated) {
    return (
      <Navigate
        to={props.unauthenticatedRedirectPath || defaultPaths?.loginPath}
        state={{ from: location }}
        replace />
    );
  }

  return props.children;
}

type UnauthenticatedGuardProps = {
  children: React.ReactNode;
};

function UnauthenticatedGuard(props: UnauthenticatedGuardProps) {
  const location = useLocation();
  const appState = useAppSelector(state => state.appState);
  const defaultPaths = useContext(ProvideDefaultPathsContext);

  if (appState.authenticated) {
    return (
      <Navigate
        to={defaultPaths.homePath}
        state={{ from: location }}
        replace />
    );
  }

  return props.children;
}