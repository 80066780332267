import { useContext, useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { ChatContainer } from '@/components/BrandInsights/Chat.Container';
import { BrandInsightsModalContext, ChatViewType, ChatViewTypeContext } from '@/components/BrandInsights/context';
import { LinkHashContainer, LinkStateContainer, useBrandInsightsHash } from '@/components/BrandInsights/Link.Container';
import { ChatModal } from '@/components/Chat.Modal';
import { ChatStateContainer } from '@/components/Chat.State';
import { ChatWidget } from '@/components/Chat.Widget';
import type { BrandInsightsCapabilities, Chat } from '@/types';

type Props = {
  author: Chat.Creator;
  capabilities: BrandInsightsCapabilities;
};

export const ChatWidgetContainer = (props: Props) => {
  const hashValue = useBrandInsightsHash();
  const matches = useMatches();

  const [open, handleToggle] = useContext(BrandInsightsModalContext);

  const enabled = useMemo(() => {
    if (open) return false;

    return matches.some(x => x.pathname.length && `/workspaces` === x.pathname.match(/^\/[^/]+/)?.[0]);
  }, [
    matches,
    open,
  ]);

  return (
    <>
      {enabled && <ChatWidget onClick={handleToggle} />}
      <ChatStateContainer>
        <LinkStateContainer initialChatIdentifier={hashValue}>
          <LinkHashContainer>
            {open && (
              <ChatViewTypeContext.Provider value={ChatViewType.Modal}>
                <ChatContainer author={props.author} capabilities={props.capabilities}>
                  <ChatModal
                    onClose={handleToggle}
                    open={open} />
                </ChatContainer>
              </ChatViewTypeContext.Provider>
            )}
          </LinkHashContainer>
        </LinkStateContainer>
      </ChatStateContainer>
    </>
  );
};