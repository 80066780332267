import { Workbook as WB } from '@/components/Workbook';
import { useWorkbookData, useWorkbookDataLoading } from '@/containers/Workspace.File.Preview/hooks';

export const Workbook = () => {
  const loading = useWorkbookDataLoading();
  const workbook = useWorkbookData();

  if (loading) {
    return null;
  }

  return (
    <WB workbook={workbook} />
  );
};

export default Workbook;
