import { useContext } from 'react';
import { SearchLoadingFileContext, SearchResultsFileContext } from '@/containers/WorkspaceGlobalSearch/Context';
import { formatPlurality } from '@/utils';
import Table from './Search.Table.Files';
import styles from './style/Tab.module.css';

const max = 50;

export default function SearchTabFiles() {
  const files = useContext(SearchResultsFileContext);
  const loading = useContext(SearchLoadingFileContext);

  const isLoading = loading.untouched && loading.loading;

  const data = files?.items ?? [];
  const countText = !isLoading
    ? `(${getCountLabel(files?.total ?? 0, 'File')})`
    : null;
  const pagination = data.length > 25;

  return (
    <div className={styles.root}>
      <Table
        data={data}
        countText={countText}
        pagination={pagination}
        pageSize={25}
        isLoading={isLoading} />
    </div>
  );
}

function getCountLabel(total: number, label: string) {
  return total > max
    ? `+${max} ${formatPlurality(label, max)}`
    : `${total} ${formatPlurality(label, total)}`;
}

export { SearchTabFiles };