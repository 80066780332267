import { Component } from 'react';

class User extends Component {
  static defaultProps = {
    size: 65,
    stroke: 'white',
    strokeWidth: '0.5px',
    fill: 'var(--black-l)',
  };

  render() {
    const { className, size, stroke, strokeWidth, fill, ...otherProps } = this.props;
    return (
      <svg
        className={className || ''}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="2 2 20 20"
        {...otherProps}
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z M 7.07 18.28 c 0.43 -0.9 3.05 -1.78 4.93 -1.78 s 4.51 0.88 4.93 1.78 C 15.57 19.36 13.86 20 12 20 s -3.57 -0.64 -4.93 -1.72 Z m 11.29 -1.45 c -1.43 -1.74 -4.9 -2.33 -6.36 -2.33 s -4.93 0.59 -6.36 2.33 C 4.62 15.49 4 13.82 4 12 c 0 -4.41 3.59 -8 8 -8 s 8 3.59 8 8 c 0 1.82 -0.62 3.49 -1.64 4.83 Z M 12 6 c -1.94 0 -3.5 1.56 -3.5 3.5 S 10.06 13 12 13 s 3.5 -1.56 3.5 -3.5 S 13.94 6 12 6 Z m 0 5 c -0.83 0 -1.5 -0.67 -1.5 -1.5 S 11.17 8 12 8 s 1.5 0.67 1.5 1.5 S 12.83 11 12 11 Z"
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill={fill}
        />
      </svg>
    );
  }
}

export { User };
export default User;
