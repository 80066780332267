import { memo, useMemo } from 'react';
import type { LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import type { Options as MarkdownOptions } from 'react-markdown';
import { SeeMore, Expander, Collapser } from '@/components/presentation';
import { ChatMarkdown } from '@/components/presentation/Markdown/Markdown';
import { ChatCitationType } from '@/enums/chat';
import type { Chat } from '@/types';
import { MessageCitationMaybeLink } from './Citation.MaybeLink';

type Props = {
  className?: string;
  citationType: ChatCitationType;
  item: Chat.CitationSnippet;
  maxLines?: number;
  to: LinkProps['to'];
};

export const MessageCitationSnippet = memo(({ citationType, className, item, to, maxLines = 20 }: Props) => {
  const customMarkdownComponents = useMemo((): MarkdownOptions['components'] => citationType === ChatCitationType.Transcript ? {
    h4: props => (
      <StyledH4>
        {props.children}
      </StyledH4>
    ),
  } : undefined, [citationType]);

  return (
    <SnippetRoot className={className}>
      <Wrap>
        <SeeMore
          ExpanderComp={SeeMoreExpander}
          CollapserComp={SeeMoreCollapser}
          lineHeight={SnippetLineHeight}
          maxLines={maxLines}>
          <SnippetText>
            {item.isMarkdown
              ? (
                <ChatMarkdown
                  components={customMarkdownComponents}
                  value={item.text} />
              )
              : item.text
            }
          </SnippetText>
        </SeeMore>
        {!!to && (
          <Footer>
            <MessageCitationMaybeLink to={to}>
              <View>{`View >`}</View>
            </MessageCitationMaybeLink>
          </Footer>
        )}
      </Wrap>
    </SnippetRoot>
  );
});

const SnippetLineHeight = 18;

export const SnippetRoot = styled.div`
  line-height: ${SnippetLineHeight}px;
  box-sizing: border-box;
  background-color: ${props => props.theme.palette.gray.light3};
  padding: 12px;
`;

const Wrap = styled.div`
`;

export const SnippetText = styled.div`
  white-space: pre-wrap;
  font-family: ${props => props.theme.fonts.regular};
  font-size: 14px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const View = styled.div`
  font-size: 13px;
  font-family: ${props => props.theme.fonts.semiBold};
`;

const SeeMoreExpander = styled(Expander)`
  background-color: ${props => props.theme.palette.gray.light3} !important;
`;

const SeeMoreCollapser = styled(Collapser)`
  background-color: ${props => props.theme.palette.gray.light3} !important;
`;

const StyledH4 = styled.h4(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 14,
  marginTop: 18,
  marginBottom: 2,

  '&:first-child': {
    marginTop: 0,
  },
}));