import { AccelerateIcon } from './Icon.Accelerate';
import { ChatIcon } from './Icon.Chat';
import { InsightsIcon } from './Icon.Insights';
import type { GetStartedItem } from './interfaces';

export const items: GetStartedItem[] = [
  {
    icon: ChatIcon,
    title: (
      <>
        Ask questions in an easy<br />
        to use chat interface
      </>
    ),
  },
  {
    icon: InsightsIcon,
    size: 68,
    title: (
      <>
        Surface insights from<br />
        documents
      </>
    ),
  },
  {
    icon: AccelerateIcon,
    size: 55,
    title: (
      <>
        Accelerate work with<br />
        AI-powered first drafts
      </>
    ),
  },
];