import { ChatQueryStatus } from '@/enums';
import { getQuerySiblings } from '@/utils/queries';
import type { ChatState, SettingsState } from './interfaces';

export function settingsReducer(state: SettingsState.State, action: ChatState.Action): SettingsState.State {

  switch (action.type) {


    default:
      return state;
  }
}