let activeUtterance: SpeechSynthesisUtterance | null = null;
let activeContent: string | null = null;
let isSpeaking = false;

const preprocessContent = (text: string) => {
  return text
    .replace(/\*/g, '') // Remove asterisks
    .replace(/\bAI\b/g, '') // Skip "AI"
    .replace(/\bPharmD\b/g, 'Pharm Dee'); // Pronounce "PharmD" correctly
};

export function startSpeech(
  content: string,
  callbacks: { onStart?: () => void; onEnd?: () => void; onError?: () => void } = {},
) {
  const synth = window.speechSynthesis;

  if (activeUtterance) {
    synth.cancel();
  }

  if (!content) return;

  // Preprocess the content
  const processedContent = preprocessContent(content);

  const utterance = new SpeechSynthesisUtterance(processedContent);
  utterance.lang = 'en-US';
  utterance.rate = 1;
  utterance.pitch = 1;

  utterance.onstart = () => {
    isSpeaking = true;
    activeUtterance = utterance;
    activeContent = content;
    callbacks.onStart?.();
  };

  utterance.onend = () => {
    isSpeaking = false;
    activeUtterance = null;
    activeContent = null;
    callbacks.onEnd?.();
  };

  utterance.onerror = (error) => {
    console.error('Speech synthesis error:', error);
    isSpeaking = false;
    activeUtterance = null;
    activeContent = null;
    callbacks.onError?.();
  };

  synth.speak(utterance);
}

export function stopSpeech() {
  if (activeUtterance) {
    window.speechSynthesis.cancel();
    isSpeaking = false;
    activeUtterance = null;
    activeContent = null;
  }
}

export function isSpeechActive(content?: string) {
  if (!content) {
    return isSpeaking;
  }
  return activeContent === content;
}