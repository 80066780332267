import styled from '@emotion/styled';
import { getCitationNumberStyles } from '../CitationNumber';

export const StyledStrong = styled.strong(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));

export const StyledP = styled.p({
  marginTop: 8,
  marginBottom: 0,

  '&:first-child': {
    marginTop: 0,
  },
});

export const StyledOL = styled.ol({
  paddingInlineStart: 16,
});

export const StyledA = styled.a(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',

  ':hover': {
    color: theme.palette.primary.main,
  },
}));

export const StyledUl = styled.ul({
  paddingInlineStart: 16,
});

export const StyledLi = styled.li(({ theme }) => ({
  marginTop: 8,
  marginBottom: 8,

  '&::last-child': {
    marginBottom: 0,
  },

  '::marker': {
    fontFamily: theme.fonts.semiBold,
  },
}));

export const StyledH1 = styled.h1(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  fontSize: 18,
  marginTop: 22,
  marginBottom: 10,

  '&:first-child': {
    marginTop: 0,
  },
}));

export const StyledH2 = styled.h2(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  fontSize: 16,
  marginTop: 22,
  marginBottom: 10,

  '&:first-child': {
    marginTop: 0,
  },
}));

export const StyledH3 = styled.h3(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 16,
  marginTop: 18,
  marginBottom: 6,

  '&:first-child': {
    marginTop: 0,
  },
}));

export const StyledH4 = styled.h4(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  fontSize: 16,
  marginTop: 18,
  marginBottom: 2,

  '&:first-child': {
    marginTop: 0,
  },
}));

export const StyledTable = styled.table({
  borderSpacing: 0,
  borderCollapse: 'collapse',
  display: 'block',
  marginTop: 0,
  marginBottom: 16,
  width: 'max-content',
  maxWidth: '100%',
  overflow: 'auto',
});

export const StyledTr = styled.tr({
  backgroundColor: '#ffffff',
  borderTop: `1px solid hsla(210, 18%, 87%, 1)`,

  ':nth-child(2n)': {
    backgroundColor: '#f6f8fa',
  },
});

export const StyledTd = styled.td({
  padding: '6px 13px',
  border: `1px solid #d0d7de`,
});

export const StyledTh = styled.th(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  padding: '6px 13px',
  border: `1px solid #d0d7de`,
}));

export const StyledSup = styled.sup(({ theme }) => ({
  ...getCitationNumberStyles(theme),

  cursor: 'pointer',
  marginLeft: 2,
}));

export const StyledMark = styled.mark({
  backgroundColor: 'yellow',
  borderRadius: 3,
});

export {
  StyledTable as Table,
};