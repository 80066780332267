import type { Tag, WorkspaceSearchFull } from '@/types';

export type FilesTableItem = {
  name: string;
  path: string;
  metadata: {
    extension: string;
  };
  tags: Tag[];
  owner: string;
  createdOn: Date;
};

export type FoldersTableItem = {
  name: string;
  path: string;
  metadata: Record<string, unknown>;
  tags: Tag[];
  owner: string;
  createdOn: Date;
};

export type TableItem =
  | FilesTableItem
  | FoldersTableItem;

export enum SearchRouteKey {
  All           = 'all',
  // Members       = 'members',
  Files         = 'files',
  Folders       = 'folders',
}

export type SearchSceneMap = {
  [key in SearchRouteKey]: TabScene;
};

export type TabScene = {
  component: React.ComponentType;
  title:     string | React.ReactNode;
};

export declare namespace IMainSearchFilters {
  export type State = {
    tab: WorkspaceSearchFull.SearchTab;
    keywords: string[];
    tags: Tag[];
    filetypes: WorkspaceSearchFull.File.FileTypes[];
  };

  export type Action =
    | { type: 'query-update'; value: Partial<State> };
}

export type ReducedFiltersContextValue = IMainSearchFilters.State;