import { useMemo } from 'react';
import { config } from '@/config';
import { RefGroupTypeEnum } from '@/enums/group';
import { Permission } from '@/enums/permission';
import { useAppSelector } from './store';

export const useIsDemoGroup = () => {
  return useAppSelector(state => state.group.typeId === RefGroupTypeEnum.Demo);
};

export const useHasWorkspacesEnabled = () => {
  return useAppSelector(state => state.group?.features?.workspaces);
};

export const useHasHcpProfilesEnabled = () => {
  return useAppSelector(state => state.group?.features?.hcpProfiles);
};

export const useHasChatVoiceInputEnabled = () => {
  return useAppSelector(state => state.group?.features?.chatVoiceInput);
};

export const useHasChatReadAloudEnabled = () => {
  return useAppSelector(state => state.group?.features?.chatReadAloud);
};

export const useHasBetaPermission = () => {
  const hasBetaPermission = useAppSelector(s => s.user.permissions.includes(Permission.BetaAccess));

  return useMemo(() => {
    return hasBetaPermission || config().APP_ENVIRONMENT !== 'production';
  }, [hasBetaPermission]);
};