import { useCallback, useState } from 'react';
import { ProgressOverlay } from '@/components/presentation/ProgressOverlay';
import { useInterval } from '@/utils/hooks';

export const FilePreviewInProgress = () => {

  const [progress, setProgress] = useState(0);

  const incrementProgress = useCallback(() => {
    setProgress(p => Math.min(p + 10, 90));
  }, []);

  useInterval(incrementProgress, 750);

  return (
    <ProgressOverlay
      progress={progress} />
  );
};

export default FilePreviewInProgress;