import { useMemo, Children } from 'react';
import { type Options as MarkdownOptions } from 'react-markdown';
import { Citation, Citations } from './Citations';
import {
  StyledA,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledLi,
  StyledMark,
  StyledOL,
  StyledP,
  StyledStrong,
  StyledTd,
  StyledTh,
  StyledTr,
  StyledUl,
  StyledTable,
} from './MarkdownElements';

export function useMarkdownComponents(components?: MarkdownOptions['components']): MarkdownOptions['components'] {

  return useMemo(() => ({
    citations: props => {
      const citations = props.node.properties.values as string[];
      return (
        <Citations values={citations} />
      );
    },
    td: props => {
      const content = Children.map(props.children, c => {
        if (c === '<br />') {
          return <br />;
        }
        return c;
      });

      return (
        <StyledTd>
          {content}
        </StyledTd>
      );
    },
    p: props => (
      <StyledP>
        {props.children}
      </StyledP>
    ),
    a: props => (
      <StyledA
        href={props.href}
        target="_blank"
        rel="noopener">
        {props.children}
      </StyledA>
    ),
    ul: props => (
      <StyledUl>
        {props.children}
      </StyledUl>
    ),
    li: props => (
      <StyledLi>
        {props.children}
      </StyledLi>
    ),
    ol: props => (
      <StyledOL>
        {props.children}
      </StyledOL>
    ),
    strong: props => (
      <StyledStrong>
        {props.children}
      </StyledStrong>
    ),
    h1: props => (
      <StyledH1>
        {props.children}
      </StyledH1>
    ),
    h2: props => (
      <StyledH2>
        {props.children}
      </StyledH2>
    ),
    h3: props => (
      <StyledH3>
        {props.children}
      </StyledH3>
    ),
    h4: props => (
      <StyledH4>
        {props.children}
      </StyledH4>
    ),
    table: props => (
      <StyledTable>
        {props.children}
      </StyledTable>
    ),
    tr: props => (
      <StyledTr>
        {props.children}
      </StyledTr>
    ),
    th: props => (
      <StyledTh>
        {props.children}
      </StyledTh>
    ),
    citation: props => {
      const citation = props.node.properties.value as string;
      return (
        <Citation value={citation} />
      );
    },
    mark: props => (
      <StyledMark>
        {props.children}
      </StyledMark>
    ),
    ...components,
  }), [components]);
}