import { useContext } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import GroupTag from '@/components/presentation/TruncatedTags';
import * as Tab from '@/components/presentation/Workspace.Tab.Pane';
import { GroupTagsContext } from '@/containers/GroupTags/context';
import { useWorkspaceFileState } from '@/containers/Workspace.File.Preview/hooks';
import styles from './style/Tab.Details.module.css';

type Props = unknown;

export const FileTabDetails = (props: Props) => {
  const { file, object } = useWorkspaceFileState();

  // const filteredAuthors = file.authors.filter(f => !!f.name || !!f.affiliation);

  return (
    <Tab.Panes.Container>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Items.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>Name</Tab.Item.TextLabel>
            <Tab.Item.Value>{object.name}</Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Owner</Tab.Item.TextLabel>
            <Tab.Item.Value>{object.owner.name}</Tab.Item.Value>
          </Tab.Item.Container>

          {/* <Tab.Item.Container>
            <Tab.Item.TextLabel>Authors</Tab.Item.TextLabel>
            <Tab.Item.Value>
              {filteredAuthors.length ? (
                filteredAuthors.map(({ name, affiliation }, index) => (
                  <div key={index}>
                    {[name, affiliation].filter(Boolean).join(', ')}
                  </div>
                ))
              ) : (
                <>None</>
              )}
            </Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Title</Tab.Item.TextLabel>
            <Tab.Item.Value>{file.title || 'Unknown'}</Tab.Item.Value>
          </Tab.Item.Container> */}

          <Tab.Items.Row>
            <Tab.Item.Container className={styles.field}>
              <Tab.Item.TextLabel>Uploaded On</Tab.Item.TextLabel>
              <Tab.Item.Value>{format(object.createdOn, 'M/d/yyyy')}</Tab.Item.Value>
            </Tab.Item.Container>
          </Tab.Items.Row>

          <Tab.Items.Row>
            <Tab.Item.Container className={styles.field}>
              <Tab.Item.TextLabel>Published On</Tab.Item.TextLabel>
              <Tab.Item.Value>{file.publishedOn ? format(file.publishedOn, 'M/d/yyyy') : 'None'}</Tab.Item.Value>
            </Tab.Item.Container>

            <Tab.Item.Container className={styles.field}>
              <Tab.Item.TextLabel>Expiration Date</Tab.Item.TextLabel>
              <Tab.Item.Value>{object.expiresOn ? format(object.expiresOn, 'M/d/yyyy') : 'None'}</Tab.Item.Value>
            </Tab.Item.Container>
          </Tab.Items.Row>
        </Tab.Items.Container>
      </Tab.Pane.Pane>
      <TagPane />
    </Tab.Panes.Container>
  );
};


function TagPane() {

  const tags = useContext(GroupTagsContext);

  if (!tags.length) {
    return null;
  }

  return (
    <Tab.Pane.Pane>
      <Tab.Pane.Title>Tags</Tab.Pane.Title>

      <Tags>
        {tags.map(tag => (
          <GroupTag key={tag.id}>
            {tag.name}
          </GroupTag>
        ))}
      </Tags>
    </Tab.Pane.Pane>
  );
}

const Tags = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 5,
});