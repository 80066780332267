import { useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AppThemingContext } from '@/containers/AppTheming/Context';

export default function FiltersCheckbox({ className, checked, label, onChange }: FiltersCheckboxProps) {
  const { palette, theme } = useContext(AppThemingContext);

  const checkedColor = theme
    ? palette.primary.main
    : `var(--pri-02)`;

  const displayLabel = `${label}`;

  return (
    <FormControlLabel
      className={className}
      sx={{
        marginLeft: 0,
        userSelect: 'none',
        [`& .MuiFormControlLabel-label`]: {
          fontSize: '15px',
          fontFamily: 'var(--font-reg)',
          width: '100%',
        },
      }}
      control={
        <Checkbox
          disableRipple={true}
          color="default"
          sx={{
            color: 'var(--gray-d)',
            padding: 0,
            marginRight: '6px',
            flexShrink: 0,
            [`&.Mui-checked`]: {
              color: checkedColor,
            },
          }}
          checked={checked ?? false}
          onChange={onChange} />
      }
      label={displayLabel} />
  );
}

export { FiltersCheckbox };

type FiltersCheckboxProps = {
  className: string;
  checked: boolean;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};