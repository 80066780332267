import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import type { RootState } from '@/store/store';

const selectAuthor = (user: RootState['user']) => {
  if (!user) {
    return {
      id: null,
      profile: {
        firstName: '',
        lastName: '',
      },
    };
  };

  return {
    id: user.id,
    profile: {
      firstName: user.profile.firstName,
      lastName: user.profile.lastName,
    },
  };
};


const userSelector = (state: RootState) => state.user;

const selector = createSelector([userSelector], selectAuthor);

export const useBrandInsightsAuthor = () => {

  const author = useSelector(selector);

  return author;
};