import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Cells from '../Files.Table.Cells';
import { useSelectObjectsEnabled } from './useSelectObjectsEnabled';

export const useFilesTableColumns = () => {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const selectObjectsEnabled = useSelectObjectsEnabled();

  return useMemo(() => [
    selectObjectsEnabled ? {
      accessor: 'checkbox',
      id: 'checkbox',
      Header: '',
      Cell: Cells.Checkbox,
      width: 30,
    } : null,
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: Cells.Name,
      minWidth: 350,
    },
    {
      accessor: 'tags',
      id: 'tags',
      Header: 'Tags',
      Cell: Cells.Tags,
      minWidth: 250,
    },
    // {
    //   accessor: 'bookmark',
    //   id: 'bookmark',
    //   Header: 'Bookmark',
    //   Cell: Cells.Bookmark,
    //   minWidth: 120,
    //   maxWidth: 120,
    // },
    isDesktop ? {
      accessor: 'owner',
      id: 'owner',
      Header: 'Owner',
      Cell: Cells.Owner,
      width: 80,
    } : undefined,
    isDesktop ? {
      accessor: 'createdOn',
      id: 'createdOn',
      Header: 'Created On',
      Cell: Cells.CreatedOn,
      minWidth: 80,
      width: 80,
    } : undefined,
    {
      id: 'actions',
      Cell: Cells.Actions,
      minWidth: 50,
      width: 50,
      disableSortBy: true,
    },
  ].filter(Boolean), [
    isDesktop,
    selectObjectsEnabled,
  ]);
};