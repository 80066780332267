import { useContext, useMemo } from 'react';
import { ChatContextType } from '@/enums/chat';
import { ActiveChatSessionContext, ChatHintsContext } from './context';
import { useChatPermissions } from './hooks';
import { BasicSystemUserMessage } from './Message.System.Basic';

export const InitialSystemMessage = () => {
  const chat = useContext(ActiveChatSessionContext);
  const hintsCtx = useContext(ChatHintsContext);
  const permissions = useChatPermissions();

  const hints = useMemo(() => {
    return permissions.canModify && hintsCtx.slice(0, 5);
  }, [permissions.canModify, hintsCtx]);

  switch (chat.context?.type) {
    case ChatContextType.Document:
      return (
        <BasicSystemUserMessage hints={hints}>
          Ask a question about the document!
        </BasicSystemUserMessage>
      );

    case ChatContextType.Transcript:
      return (
        <BasicSystemUserMessage hints={hints}>
          Ask a question about the transcript!
        </BasicSystemUserMessage>
      );

    case ChatContextType.Folder:
      return (
        <BasicSystemUserMessage hints={hints}>
          Ask a question about documents in this folder!
        </BasicSystemUserMessage>
      );

    case ChatContextType.Global:
      return (
        <BasicSystemUserMessage hints={hints}>
          Ask a question about your organization's approved medical literature and documents!
          <br />
          <br />
          Enter your question below to gain insights on relevant topics or choose one of the example questions to start a conversation.
        </BasicSystemUserMessage>
      );

    default: return null;
  }
};