import { forwardRef, useRef } from 'react';
import { cx } from '@/utils';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';
import type { VideoPlayerProps } from './interfaces';
import styles from './style.module.css';

type Props =
  VideoPlayerProps;

const VideoPlayer = ({
  children = (<p>Your browser does not support the <code>video</code> tag.</p>),
  ...props
}: Props, ref: React.ForwardedRef<HTMLVideoElement>) => {
  const internalRef = useRef<HTMLVideoElement>();
  useKeyboardShortcuts(internalRef);
  return (
    <video
      ref={n => {
        internalRef.current = n;

        if (!ref) return;

        if (typeof ref === 'function') {
          ref(n);
        } else {
          ref.current = n;
        }
      }}
      className={cx(styles.videoPlayer, props.className)}
      crossOrigin="anonymous"
      controls={props.controls}
      style={props.style}
      src={props.src}>
      <source
        src={props.src}
        type="video/mp4" />
      {children}
    </video>
  );
};

const VideoPlayerWithRef = forwardRef<HTMLVideoElement, Props>(VideoPlayer);

export { VideoPlayerWithRef as VideoPlayer };
export default VideoPlayerWithRef;