import type { VeevaUtilities } from '@/types/vendor.veeva';

export interface MyInsightsVeevaUtils {
  getQueryOpenBrace(): string;
  getQueryCloseBrace(): string;
  formatInArrayStatement(values: Array<string | number>): string;
}

export class MyInsightsVeevaOnlineUtils implements MyInsightsVeevaUtils {
  #utilsInstance: VeevaUtilities;

  #getInstance() {
    if (this.#utilsInstance) return this.#utilsInstance;
    if (!window.VeevaUtilities) throw new Error('Attemping to use Veeva Utilities but the library is not available.');
    this.#utilsInstance = new window.VeevaUtilities();
    return this.#utilsInstance;
  }

  getQueryOpenBrace() {
    return this.#getInstance().isOnline() ? '(' : '{';
  }

  getQueryCloseBrace() {
    return this.#getInstance().isOnline() ? ')' : '}';
  }

  formatInArrayStatement(values: Array<string | number>) {
    const openBrace = this.getQueryOpenBrace();
    const closeBrace = this.getQueryCloseBrace();

    return values?.length
      ? `${openBrace}'${values.join(`', '`)}'${closeBrace}`
      : `${openBrace}${closeBrace}`;
  }
}

export class MyInsightsVeevaMockUtils implements MyInsightsVeevaUtils {
  getQueryOpenBrace() {
    return '(';
  }

  getQueryCloseBrace() {
    return ')';
  }

  formatInArrayStatement(values: Array<string | number>) {
    const openBrace = this.getQueryOpenBrace();
    const closeBrace = this.getQueryCloseBrace();

    return values?.length
      ? `${openBrace}'${values.join(`', '`)}'${closeBrace}`
      : `${openBrace}${closeBrace}`;
  }
}