import { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { BrandInsightsModalContext, LinkContext, LinkDispatchContext } from './context';
import type { ILink } from './interfaces';

type Props = ChildrenProps;

export function LinkHashContainer({ children }: Props) {
  const initializedIdentifier = useRef<string>(null);
  const [isOpen, toggleModal] = useContext(BrandInsightsModalContext);

  const hashValue = useBrandInsightsHash();
  const link = useContext(LinkContext);

  useEffect(() => {
    if (!hashValue) {
      return;
    }

    if (link.chatIdentifier !== hashValue) {
      // return;
    }

    if (!initializedIdentifier.current || initializedIdentifier.current !== hashValue) {
      initializedIdentifier.current = hashValue;
      if (!isOpen) {
        console.log('Hash value changed, opening modal', hashValue);
        toggleModal();
      }
    }
  }, [isOpen, link.chatIdentifier, toggleModal, hashValue]);

  return (
    <>
      {children}
    </>
  );
}

type LinkStateProps = {
  initialChatIdentifier?: string;
} & ChildrenProps;

export const LinkStateContainer = ({ children, initialChatIdentifier }: LinkStateProps) => {

  const [state, dispatch] = useReducer(reducer, getInitialState(initialChatIdentifier));

  const value = useMemo(() => ({
    chatIdentifier: state.chatIdentifier,
    initialized: state.initialized,
    error: state.error,
  }), [state.chatIdentifier, state.initialized, state.error]);

  return (
    <LinkContext.Provider value={value}>
      <LinkDispatchContext.Provider value={dispatch}>
        {children}
      </LinkDispatchContext.Provider>
    </LinkContext.Provider>
  );
};

export function useBrandInsightsHash() {
  // #bi:ocid:identifier
  const { hash } = useLocation();

  const parseHash = useCallback((value: string) => {
    if (!value?.length) {
      return;
    }

    const parts = value.slice(1).split(':');

    if (parts.length !== 3 || parts[0] !== 'bi' || parts[1] !== 'ocid') {
      return;
    }

    const chatIdentifier = parts[2];
    return chatIdentifier;
  }, []);

  return useMemo(() => {
    return parseHash(hash);
  }, [hash, parseHash]);
}

function reducer(state: ILink.State, action: ILink.Action): ILink.State {
  switch (action.type) {
    case 'update-chat-identifier':
      return {
        ...state,
        chatIdentifier: action.payload.chatIdentifier,
        initialized: false,
        error: false,
      };

    case 'update-chat-initialized':
      return {
        ...state,
        error: action.payload.error,
        initialized: true,
      };

    case 'state-reset':
      return action.payload;

    default: return state;
  }
}

function getInitialState(chatIdentifier: string): ILink.State {
  return {
    chatIdentifier,
    initialized: !chatIdentifier,
    error: false,
  };
}