export enum WorkspaceObjectType {
  Folder = 1,
  ProjectParent = 2,
  File = 3,
  Workspace = 4,
  CallTranscript = 5,
  Brand = 6,
}

export enum WorkspaceObjectRole {
  Collaborator = 1,
  Viewer = 2,
  Owner = 3,
  Attendee = 4,
}

export enum WorkspaceType {
  Personal = 1,
  Group = 2,
}

export const WorkspaceTypeMap = new Map([
  [WorkspaceObjectType.Folder, { name: 'Folder' }],
  [WorkspaceObjectType.ProjectParent, { name: 'Project' }],
  [WorkspaceObjectType.Workspace, { name: 'Workspace' }],
  [WorkspaceObjectType.File, { name: 'File' }],
  [WorkspaceObjectType.CallTranscript, { name: 'Transcript' }],
  [WorkspaceObjectType.Brand, { name: 'Brand' }],
]);

export const WorkspaceRoleMap = new Map([
  [WorkspaceObjectRole.Collaborator, { name: 'Collaborator' }],
  [WorkspaceObjectRole.Viewer, { name: 'Viewer' }],
  [WorkspaceObjectRole.Owner, { name: 'Owner' }],
  [WorkspaceObjectRole.Attendee, { name: 'Attendee' }],
]);