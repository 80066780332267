import { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Folder } from 'react-feather';
import { HorizonalTrinity } from '@/components/Branding/Logo';
import { InsightsChatIcon } from '@/components/icons/InsightsChat';
import { User } from '@/components/icons/User';
import { WorkspaceGlobalSearchBar } from '@/components/WorkspaceGlobalSearchBar';
import { useAppSelector } from '@/store';
import { useHasWorkspacesEnabled } from '@/store/selectors';

export const NavBar = memo(() => {
  const workspacesEnabled = useHasWorkspacesEnabled();
  const authenticated = useAppSelector(state => state.appState.authenticated);

  return (
    <Root>
      <Wrap>
        <Left>
          <Link to="/">
            <HorizonalTrinity />
          </Link>
          {workspacesEnabled && <WorkspaceGlobalSearchBar />}
        </Left>

        {authenticated && (
          <Right>
            {workspacesEnabled && (
              <DropdownNavLink to="/workspaces">
                <StyledFolder />
                <NavLabel>Documents</NavLabel>
              </DropdownNavLink>
            )}
            <DropdownNavLink to="/chat">
              <StyledInsightsChatIcon />
              <NavLabel>Insights Chat</NavLabel>
            </DropdownNavLink>
            <Dropdown>
              <DropdownToggle>
                <StyledUser />
                <NavLabel>Account</NavLabel>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem to="/logout">
                  <DropdownText>Log Out</DropdownText>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Right>
        )}
      </Wrap>
    </Root>
  );
});

const Root = styled.div(({ theme }) => ({
  top: 0,
  width: '100%',
  position: 'fixed',
  boxShadow: '0 0 0 0 #0000001a,0 1px 0 0 #0000001a,0 0 0 0 #0000001f',
  backgroundColor: 'white',
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  zIndex: 5,
}));

const Wrap = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px',
  height: 60,
  maxWidth: 1280,
  margin: '0 auto',
});

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 70px;
`;

const StyledFolder = styled(Folder)`
  color: var(--black-l);
  margin-bottom: 4px;
  width: 30px;
  height: 30px;
`;

const StyledInsightsChatIcon = styled(InsightsChatIcon)`
  color: var(--black-l);
  margin-bottom: 4px;
  width: 30px;
  height: 30px;
`;

const StyledUser = styled(User)`
  color: var(--black-l);
  margin-bottom: 4px;
  width: 30px;
  height: 30px;
`;

const NavLabel = styled.div`
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 11px;
  text-align: center;
`;

const Dropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DropdownNavLink = styled(Link)(({ theme }) => ({
  background: 'none',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: theme.palette.gray.dark1,
  height: '60px',
  ':hover': {
    color: theme.palette.gray.dark1,
  },
}));

const DropdownToggle = styled.button(({ theme }) => ({
  background: 'none',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: theme.palette.gray.dark1,
  height: '60px',
}));

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 3;
  min-width: 170px;
  background-color: var(--pri-01);
  background-clip: padding-box;
  box-shadow: 1px 1px 2px 1px rgba(238, 238, 238, 1);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  ${Dropdown}:hover & {
    display: block;
  }
`;

const DropdownItem = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '50px',
  paddingLeft: '20px',
  boxSizing: 'border-box',
  borderLeft: `1px solid var(--gray-l)`,
  borderRight: `1px solid var(--gray-l)`,
  color: theme.palette.gray.dark1,
  ':first-of-type': {
    borderTop: `1px solid var(--gray-l)`,
  },
  ':last-child': {
    paddingBottom: '10px',
    height: '60px',
    borderBottom: `1px solid var(--gray-l)`,
    borderBottomRightRadius: '6px',
    borderBottomLeftRadius: '6px',
  },
  ':hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.isTrinityThemed ? theme.palette.sentiment.primary.light : theme.palette.btnPrimary.bg,
  },
}));

const DropdownText = styled.div`
  font-family: var(--font-semibold);
  color: var(--black-l);
`;
