import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLastLoginStrategy } from '@/auth/session';
import { useEnterpriseSubmitMutation } from '@/containers/Auth';

export function OAuth2Login() {

  const loginMutation = useEnterpriseSubmitMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const lastLoginStrategy = getLastLoginStrategy();

    if (lastLoginStrategy?.strategy === 'standard') {
      return navigate('/_login');
    } else {
      const provider = lastLoginStrategy?.strategy === 'enterprise' ? lastLoginStrategy.identifier : undefined;

      loginMutation.mutateAsync({
        provider,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <></>
  );
}