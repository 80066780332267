import { z } from 'zod';

export abstract class BaseAuthProvider {
  abstract get isAuthenticated(): boolean;
  abstract get isRefreshingToken(): boolean;
  abstract get accessToken(): string;
  abstract get expiry(): Date;
  abstract get authSource(): AuthSource;
  abstract get authAttempts(): number;
  abstract authenticate(data: AuthenticateParams): Promise<void>;
  abstract error(e: Error): Promise<void>;
  abstract refreshToken(): Promise<void>;
  abstract logout(): void;
  abstract incrementAuthAttempts(): void;

  abstract addListener(listener: (e: AuthProviderEvent) => void): void;
  abstract removeListener(listener: (e: AuthProviderEvent) => void): void;
}

export const AuthSource = z.enum(['cookie', 'jwt']);
export type AuthSource = z.infer<typeof AuthSource>;

export const AuthenticateCookie = z.object({
  authSource: z.literal(AuthSource.enum.cookie),
  sessionMeta: z.object({
    authenticated: z.boolean(),
    expiry: z.date(),
  }),
});
export type AuthenticateCookie = z.infer<typeof AuthenticateCookie>;

export const AuthenticateJWT = z.object({
  authSource: z.literal(AuthSource.enum.jwt),
});
export type AuthenticateJWT = z.infer<typeof AuthenticateJWT>;

export const AuthenticateParams = z.discriminatedUnion('authSource', [AuthenticateCookie, AuthenticateJWT]);
export type AuthenticateParams = z.infer<typeof AuthenticateParams>;

const AuthProviderErrorEvent = z.object({
  name: z.literal('auth/error'),
  error: z.object({
    name: z.string(),
    message: z.string(),
    stack: z.string().nullish(),
  }),
});
type AuthProviderErrorEvent = z.infer<typeof AuthProviderErrorEvent>;

const AuthProviderLoginEvent = z.object({
  name: z.literal('auth/login'),
});

type AuthProviderLoginEvent = z.infer<typeof AuthProviderLoginEvent>;

const AuthProviderLogoutEvent = z.object({
  name: z.literal('auth/logout'),
});
type AuthProviderLogoutEvent = z.infer<typeof AuthProviderLogoutEvent>;

export const AuthProviderEvent = z.discriminatedUnion('name', [
  AuthProviderErrorEvent,
  AuthProviderLoginEvent,
  AuthProviderLogoutEvent,
]);
export type AuthProviderEvent = z.infer<typeof AuthProviderEvent>;