import qs from 'query-string';
import type { Tag, WorkspaceSearchFull } from '@/types';

export const workspaces = {
  file: (data: { fileId: number }) => ({
    pathname: `/workspaces/files/${data.fileId}`,
    state: {
      from: currentLocation(),
    },
  }),
  folder: (data: { folderId: number }) => ({
    pathname: `/workspaces/folders/${data.folderId}`,
    state: {
      from: currentLocation(),
    },
  }),
  search: (data: GetWorkspaceSearchLocation) => ({
    pathname: `/workspaces/search`,
    search: `?${qs.stringify({
      keywords: data.keywords,
      tab: data.tab,
      tags: data.tags ? JSON.stringify(data.tags) : undefined,
    })}`,
    state: {
      from: currentLocation(),
    },
  }),
};

export function currentLocation() {
  return `${window.location.pathname}${window.location.search}`;
}


type GetWorkspaceSearchLocation = {
  tab?: WorkspaceSearchFull.SearchTab;
  keywords?: string[];
  tags?: Tag[];
};
