import type { WorkspaceGlobalSearchBarResultItem } from '@/types';
// import Brand from './Results.Brand';
import File from './Results.File';
import Folder from './Results.Folder';
// import Project from './Results.Project';
import Keyword from './Results.Keyword';
import Tag from './Results.Tag';
import styles from './style/Results.module.css';

export default function ResultsItems({ activeIndex, items }: ResultsItemsProps) {
  return (
    <>
      {items.map((item, i) => {
        const isHighlighted = i === activeIndex;
        switch (item.type) {
          case 'file':
            return (
              <File
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={isHighlighted} />
            );
          case 'folder':
            return (
              <Folder
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={isHighlighted} />
            );
          // case 'project':
          //   return (
          //     <Project
          //       className={styles.result}
          //       key={item.id}
          //       item={item}
          //       index={i}
          //       highlighted={isHighlighted} />
          //   );
          case 'keyword':
            return (
              <Keyword
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={isHighlighted} />
            );
          case 'tag' : {
            return (
              <Tag
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={isHighlighted} />
            );
          }
          // case 'brand': {
          //   return (
          //     <Brand
          //       className={styles.result}
          //       key={item.id}
          //       item={item}
          //       index={i}
          //       highlighted={isHighlighted} />
          //   );
          // }
        }
      })}
    </>
  );
}

export { ResultsItems };

type ResultsItemsProps = {
  activeIndex: number;
  items: WorkspaceGlobalSearchBarResultItem[];
};