import type { AxiosError } from 'axios';
import * as cancellation from './cancellation';
import { getAuthenticatedClient } from './client';
import type { WorkspaceGlobalSearch } from './interfaces/workspace.global-search';
import * as xform from './transformers';

export const bar = (data: WorkspaceGlobalSearch.Bar.Request) => {
  return getAuthenticatedClient().post<WorkspaceGlobalSearch.Bar.Response>(`/workspaces/global-search/bar`, {
    query: data.query,
    filters: data.filters,
  }, { cancelToken: data.cancelToken })
    .catch<void>((e: AxiosError<unknown>) => cancellation.ignoreRequestCancellationError(e));
};

export const files = (data: WorkspaceGlobalSearch.Files.Request): Promise<WorkspaceGlobalSearch.Files.Response> => {
  return getAuthenticatedClient().post(`/workspaces/global-search/files`, {
    filters: data.filters,
    size: data.size,
  }).then(({ data }) => xform.normalizeSearchFullFileResults(data));
};

export const folders = (data: WorkspaceGlobalSearch.Folders.Request): Promise<WorkspaceGlobalSearch.Folders.Response> => {
  return getAuthenticatedClient().post(`/workspaces/global-search/folders`, {
    filters: data.filters,
    size: data.size,
  }).then(({ data }) => xform.normalizeSearchFullFolderResults(data));
};