import { useContext } from 'react';
import styled from '@emotion/styled';
import { LoadingProgress } from '@/components/presentation/Insights.LoadingProgress';
import { WorkspaceFileSummaryContext } from '@/containers/Workspace.File.Preview/Context';
import { SummaryActionDownload } from './Summary.Action.Download';
import { SummaryActionRefresh } from './Summary.Action.Refresh';
import { SummaryNotAvailable } from './Summary.NotAvailable';
import { SummarySections } from './Summary.Sections';

type Props = {
  className?: string;
};

export function FileSummary({ className }: Props) {
  const ctx = useContext(WorkspaceFileSummaryContext);

  const isLoading = ctx?.isLoading || ctx?.isRegenerating || ctx?.summary?.status === 'pending';
  const isUnsupported = ctx?.summary?.status === 'unsupported';
  const hasData = !!ctx?.summary?.sections?.length;

  return (
    <Root className={className}>
      <Header>
        <Truncate>
          <Title>Summary</Title>
        </Truncate>
        {!isUnsupported && (
          <Actions>
            <SummaryActionRefresh />
            {!isLoading && hasData && (
              <SummaryActionDownload />
            )}
          </Actions>
        )}
      </Header>
      <Notepad>
        {isLoading && (
          <LoadingProgress />
        )}
        {!isLoading && !hasData &&
          <SummaryNotAvailable />
        }
        {!isLoading && hasData && (
          <SummarySections items={ctx.summary.sections} />
        )}
      </Notepad>
    </Root>
  );
}

const Root = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  height: 70px;
  justify-content: space-between;
  border-bottom: 1px solid var(--sec-01);
`;

const Truncate = styled.div`
  height: 2em;
  width: 100%;
`;

const Title = styled.h2`
  color: var(--black-l);
  font-size: 22px;
  font-family: var(--font-semibold);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Notepad = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: calc(500px - 70px);
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  gap: 20px;
`;