import type { ReactNode } from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@/api';
import { LinkContext } from '@/components/BrandInsights/context';
import { ActiveChatSessionContext, AuthorContext } from '@/components/Chat';
import { ChatStateDispatchContext } from '@/components/Chat.State/context';
import { FetchMoreHistoryContext, ChatHistoryManagerContext, ChatHistoryItemsContext } from './context';

type Props = {
  children: ReactNode;
};

export const ChatHistoryContainer = (props: Props) => {
  const historyManager = useContext(ChatHistoryManagerContext);
  const historyItems = useContext(ChatHistoryItemsContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const author = useContext(AuthorContext);
  const chat = useContext(ActiveChatSessionContext);

  const link = useContext(LinkContext);

  const getIncludeAdditional = useCallback(() => {
    if (!link?.initialized && link?.chatIdentifier && !historyItems.map(i => i.identifier).includes(link.chatIdentifier)) {
      return [link.chatIdentifier];
    }
    return null;
  }, [historyItems, link?.initialized, link?.chatIdentifier]);

  const queryKey = useMemo(() => {
    return {
      mode: historyManager.mode,
      cursor: historyManager.cursor,
      pageSize: 20,
      userId: author.id,
      includeAdditional: getIncludeAdditional(),
      context: chat.context,
    };
  }, [historyManager.mode, historyManager.cursor, author.id, getIncludeAdditional, chat.context]);

  const query = useQuery({
    queryKey: [`chat:get:history`, queryKey] as const,
    queryFn: ({ queryKey }) => {
      dispatch({ type: 'chat-loading-started' });
      return $api.getChatHistory({
        mode: queryKey[1].mode,
        cursor: queryKey[1].cursor,
        pageSize: queryKey[1].pageSize,
        userId: queryKey[1].userId,
        includeAdditional: queryKey[1].includeAdditional,
        context: queryKey[1].context,
      }).then(data => {
        const cursor = data.items.length ? data.items[data.items.length - 1].id : null;
        dispatch({
          type: queryKey[1].cursor ? 'chat-history-page-loaded' : 'chat-history-loaded',
          payload: {
            cursor,
            hasMore: data.hasMore,
            items: data.items,
            shared: data.shared,
          },
        });
        return data;
      }).catch(() => {
        dispatch({ type: 'chat-loading-errored' });
      });
    },
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
  });

  const refetch = query.refetch;

  useEffect(() => {
    if (!historyManager.initialized) {
      refetch();
    }
  }, [historyManager.initialized, refetch]);

  return (
    <FetchMoreHistoryContext.Provider value={refetch}>
      {props.children}
    </FetchMoreHistoryContext.Provider>
  );
};