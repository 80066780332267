import styled from '@emotion/styled';
import { FileSummary } from '@/components/Workspace.File.Summary';
import styles from './style/Tab.module.css';

const StateDependent = () => {
  return (
    <div className={styles.content}>
      <div className={styles.col}>
        <StyledFileSummary />
      </div>
    </div>
  );
};

export const FileTabSummary = () => {
  return (
    <StateDependent />
  );
};

const StyledFileSummary = styled(FileSummary)`
  border: 1px solid var(--sec-01);
  border-radius: 4px;
`;