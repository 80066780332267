import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Helmet } from 'react-helmet';
import { BackLink } from '@/components/presentation/Back';
import styles from './style/File.NotFound.module.css';

export default function FileNotFound() {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Helmet title="File not found" />
        <div className={styles.icon}><ErrorIcon /></div>
        <div className={styles.copy}>File does not exist</div>
        <div className={styles.back}>
          <BackLink to={`/`} />
        </div>
      </div>
    </div>
  );
}

export { FileNotFound };