import { memo } from 'react';
import { Play, Pause } from 'react-feather';
import { cx } from '@/utils';
import styles from './style/Button.module.css';

type Props = {
  className?: string;
  playing: boolean;
  togglePlaying: () => void;
};

export const AudioPlayerButton = memo(({ className, playing, togglePlaying }: Props) => {

  return (
    <div
      className={cx(styles.btn, className)}
      onClick={togglePlaying}>
      {playing
        ? <Pause className={styles.pause} />
        : <Play className={styles.play} />
      }
    </div>
  );
});

export default AudioPlayerButton;