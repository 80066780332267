import { memo } from 'react';
import { cx } from '@/utils';
import type { ActiveTrackProps, InactiveTrackProps } from './interfaces';
import styles from './style/Tracks.module.css';

export const ActiveSliderTrack = memo(({ className, ...otherProps }: ActiveTrackProps) => {
  return <div className={cx(styles.active, className)} {...otherProps} />;
});

export const InactiveSliderTrack = memo(({ className, ...otherProps }: InactiveTrackProps) => {
  return <div className={cx(styles.inactive, className)} {...otherProps} />;
});