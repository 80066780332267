import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

export const ignoreRequestCancellationError = <T = unknown>(error: AxiosError<XHR.RequestConfig>, returnValue?: T): T => {
  if (!axios.isCancel(error)) throw new Error(error);

  return returnValue;
};

export declare namespace XHR {
  export type Instance<M extends XHR.Method = XHR.Method> = {
    [K in M]: XHR.RequestMethod<K>;
  } & Omit<AxiosInstance, M>;

  export type RequestConfig = {
    headers?: Headers;
    method?:  Method;
  } & Omit<AxiosRequestConfig, 'headers' | 'method'>;

  export type RequestMethod<
    T extends Method = Method,
    F extends AxiosInstance[T] = AxiosInstance[T]> = T extends 'post'
      ? <T>(url: string, data: unknown, config?: Parameters<F>[2]) => Promise<T>
      : <T>(url: string, config?: Parameters<F>[1]) => Promise<T>;

  export type Headers = {
    common?: {
      Authorization: string;
      'X-SID': string;
      'X-CSRF-Token': string;
    };
  };

  export type Method = 'patch' | 'post' | 'put' | 'get' | 'delete';

  export type Error<T = string> = {
    offline?: boolean;
    response: AxiosResponse<T>;
  } & Omit<AxiosError<T>, 'response'>;

  export type Response<T = null> =
    T extends null
      ? AxiosResponse
      : AxiosResponse<T>;
}