import { useMemo } from 'react';
import { ChatContextType } from '@/enums';
import { useAppSelector } from '@/store';

export const useChatSettings = () => {
  const groupFeatures = useAppSelector(state => state.group.features);

  const availableContextTypes = useMemo(() => {
    const values: ChatContextType[] = [];

    if (groupFeatures.hcpProfiles) {
      values.push(ChatContextType.KolProfile);
    }

    if (groupFeatures.workspaces) {
      values.push(ChatContextType.Global);
      values.push(ChatContextType.Document);
      values.push(ChatContextType.Folder);
    }

    return values;
  }, [groupFeatures]);

  const defaultContextType = useMemo(() => {
    if (groupFeatures.hcpProfiles) {
      return ChatContextType.KolProfile;
    }

    return ChatContextType.Global;
  }, [groupFeatures]);

  return useMemo(() => ({
    availableContextTypes,
    defaultContextType,
  }), [availableContextTypes, defaultContextType]);
};