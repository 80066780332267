import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { FileIcon } from '@/components/icons/File';
import type { WorkspaceGlobalSearchBarResultFile } from '@/types';
import { cx } from '@/utils';
import ClickContainer from './Results.ClickContainer';
import styles from './style/Results.File.module.css';

export default function ResultsFile({ className, index, item, highlighted }: ResultsFileProps) {
  return (
    <ClickContainer index={index} item={item}>
      <div className={cx(styles.root, { [styles.highlighted]: highlighted }, className)}>
        <FileIcon
          className={styles.icon}
          extension={item.metadata.extension} />
        <div className={styles.details}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.type}>File</div>
        </div>
        {highlighted && (
          <div className={styles.return}>
            <KeyboardReturnIcon />
          </div>
        )}
      </div>
    </ClickContainer>
  );
}

export { ResultsFile };

type ResultsFileProps = {
  className?: string;
  index: number;
  item: WorkspaceGlobalSearchBarResultFile;
  highlighted?: boolean;
};