import { useState } from 'react';
import { startSpeech, stopSpeech, isSpeechActive } from '@/utils/speech';

type UseReadAloudResult = {
  isSpeaking: boolean;
  toggleSpeech: (content: string) => void;
};

export const useReadAloud = (): UseReadAloudResult => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  const toggleSpeech = (content: string) => {
    if (isSpeechActive(content)) {
      stopSpeech();
      setIsSpeaking(false);
    } else {
      startSpeech(content, {
        onStart: () => setIsSpeaking(true),
        onEnd: () => setIsSpeaking(false),
        onError: () => setIsSpeaking(false),
      });
    }
  };

  return {
    isSpeaking,
    toggleSpeech,
  };
};
