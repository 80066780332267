import { useCallback, useMemo } from 'react';
import { addYears } from 'date-fns';
import { z } from 'zod';
import useCookie from '@/hooks/useCookie';
import { useAppSelector } from '@/store';
import * as utils from '@/utils';

const COOKIE_NAME = `chat-guidelines-shown`;

export const useChatGuidelinesCookie = () => {
  const [cookieVal, setCookieVal] = useCookie(COOKIE_NAME, '[]', { watchForChanges: true });

  const userArray = useMemo(() => {
    try {
      const parsedVal = z.array(z.number()).safeParse(JSON.parse(cookieVal));

      if (!parsedVal.success) {
        console.log(`Invalid cookie ${COOKIE_NAME}. Error: ${parsedVal.error}`);
        setCookieVal({ value: '[]', expires: addYears(new Date(), 1) });
        return [];
      }

      return parsedVal.data;
    } catch {
      return [];
    }
  }, [cookieVal, setCookieVal]);

  const userId = useAppSelector(state => state.user.id);

  const isDismissed = useMemo(() => {
    return userArray.includes(userId);
  }, [userArray, userId]);

  const dismissGuidelines = useCallback(() => {
    if (!userId) return;

    const userArr = utils.array.distinct([...userArray, userId]);

    setCookieVal({ value: JSON.stringify(userArr), expires: addYears(new Date(), 1) });
  }, [setCookieVal, userArray, userId]);

  return { isDismissed, dismissGuidelines };
};