import { css } from '@emotion/react';
import { useThemedStyles } from '@/components/Theme';
import { authorSpacing, messageStyles, messageWidth, extendedMessageWidth } from './Message.styles';

const authorWrapCss = css([messageStyles.authorWrap, {
  marginRight: authorSpacing,
}]);

const authorCss = css(messageStyles.author);

const borderCss = (theme: Theme) => css({
  border: `2px solid ${theme.palette.gray.light2}`,
});

const bodyCss = (theme: Theme) => {
  return css([messageStyles.body, borderCss(theme), {
    backgroundColor: theme.palette.white.main,
    maxWidth: extendedMessageWidth,
  }]);
};

const rootCss = css([messageStyles.root, {
  justifyContent: 'flex-start',
}]);

const skeletonTextCss = (theme: Theme) => css({
  width: messageWidth - 50,
});

const skeletonItemCss = (theme: Theme) => css({
  height: 22,
  minHeight: 22,
  backgroundColor: theme.palette.skeleton.main,
});

export const useStyles = () => useThemedStyles({
  author: authorCss,
  authorWrap: authorWrapCss,
  body: bodyCss,
  root: rootCss,
});

export const usePlaceholderStyles = () => {
  return useThemedStyles({
    author: authorCss,
    authorWrap: authorWrapCss,
    body: bodyCss,
    root: rootCss,
    skeleton: skeletonTextCss,
    skeletonItem: skeletonItemCss,
  });
};