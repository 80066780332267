import Fetch from './Containers.Fetch';
import Filters from './Containers.Filters';
import KeyboardListener from './Containers.KeyboardListener';
import Query from './Containers.Query';
import Reset from './Containers.Reset';
import Results from './Containers.Results';

export default function Containers({ children }: ContainersProps) {
  return (
    <Results>
      <Filters>
        <Query>
          <Fetch>
            <KeyboardListener>
              <Reset>
                {children}
              </Reset>
            </KeyboardListener>
          </Fetch>
        </Query>
      </Filters>
    </Results>
  );
}

type ContainersProps = {
  children: React.ReactNode;
};